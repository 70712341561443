import Box from '@luce/ui-kit/components/base/Box'
import { makeStyles, type Theme } from '@luce/ui-kit/themes/ThemeProvider'

import Loader from './Loader'

const useStyles = makeStyles((_theme: Theme) => ({
  loaderPostion: {
    position: 'absolute',
    margin: 'auto',
    height: '100%',
    top: '50%',
    left: '50%',
  },
}))

const LoadingOverlay = () => {
  const classes = useStyles()

  return (
    <Box className={classes.loaderPostion}>
      <Loader />
    </Box>
  )
}

export default LoadingOverlay
