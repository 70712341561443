import { createSlice } from '@reduxjs/toolkit'
import { DiscountTypeEnum } from '__generated__/globalTypes'
import { RootState } from 'redux/slices'

export interface RewardValues {
  rewardName: string
  validUntil: string
  code: string | null
  description: string
  discountValue: number
  discountType: DiscountTypeEnum
}
type State = {
  selectedReward: RewardValues | null
}

export const initialState: State = {
  selectedReward: null,
}

const rewardSlice = createSlice({
  name: 'rewardSlice',
  initialState,
  reducers: {
    setSelectedReward(
      state,
      {
        payload: { selectedReward },
      }: { payload: { selectedReward: RewardValues | null } }
    ) {
      state.selectedReward = selectedReward
    },
  },
})

export const selectedRewardSelector = (state: RootState) =>
  state.rewardReducer.selectedReward

const { name, actions, reducer } = rewardSlice
export { name, actions, reducer, actions as rewardActions }
