import AIRCON_CASSETE from './aircon/aircon-cassete-ceiling.png'
import AIRCON_CARD from './aircon-cleaning-card.png'
import AIRCON_DUCTED from './aircon/aircon-ducted.png'
import AIRCON_PORTABLE from './aircon/aircon-portable.png'
import AIRCON_SERVICING from './aircon-service.png'
import AIRCON_WALL from './aircon/aircon-wall.png'
import AIRCON_WINDOW from './aircon/aircon-window.png'
import APP_STORE from './app-store.svg'
import BETA_APP_IMG from './beta-app-image.png'
import BOOKING_SERVICE_AIRCON from './booking-service-aircon.jpg'
import BOOKING_SERVICE_HOME_BEAUTY from './booking-service-home-beauty.jpg'
import BOOKING_SERVICE_HOME from './booking-service-home-cleaning.jpg'
import BOOKING_SERVICE_UPHOLSTERY from './booking-service-upholstory.png'
import CARPET_CLEANING from './carpet-cleaning.png'
import CHEMICAL_OVERHAUL from './chemical-overhaul.jpg'
import CHEMICAL_WASH from './chemical-wash.png'
import CONDENSER_WASH from './condenser-wash.jpg'
import ERROR_ILLUSTRATION from './error-illustration.svg'
import FEEDBACK_WIDGET_DONE from './feedback-done.svg'
import GENERAL_SERVICING from './general-servicing.png'
import GOOGLE_PLAY from './google-play.svg'
import HOME_CLEANING_CARD from './home-cleaning-card.png'
import HOME_CLEANING_PACKAGE from './home-cleaning-package.png'
import CALLUS_TREATMENT from './homeBeauty/callus-treatment.png'
import CLASSIC_MANICURE from './homeBeauty/classic-manicure.png'
import CLASSIC_MANI_PEDI from './homeBeauty/classic-manipedi.png'
import CLASSIC_PEDICURE from './homeBeauty/classic-pedicure.png'
import EXTENDED_MESSAGE from './homeBeauty/extended-massage.png'
import EXTENSION_REMOVAL from './homeBeauty/extension-removal.png'
import FRENCH_TIPS from './homeBeauty/french-tips.png'
import GEL_MANI_CLASSIC_PEDI from './homeBeauty/gel-mani-classic-pedi.png'
import GEL_MANICURE from './homeBeauty/gel-manicure.png'
import GEL_MANI_PEDI from './homeBeauty/gel-manipedi.png'
import GEL_PEDICURE from './homeBeauty/gel-pedicure.png'
import GEL_REMOVAL from './homeBeauty/gel-removal.png'
import MANI_PEDI_SERVICE from './homeBeauty/mani-pedi-service.png'
import MANICURE_SERVICE from './homeBeauty/manicure-service.png'
import NAIL_ART from './homeBeauty/nail-art.png'
import NAIL_EXTENSION from './homeBeauty/nail-extension.png'
import PEDICURE_SERVICE from './homeBeauty/pedicure-service.png'
import LINK from './link.svg'
import SHAREICON from './SharerIcon.svg'
import LUCE_APP_PREVIEW from './luce-app-preview.png'
import LUCE_LOGO_BLUE from './luce-logo-blue.svg'
import MARBLE_POLISHING from './marble-polishing.png'
import PAYMENT_METHOD_BANKTRANSFER from './method-banktransfer.png'
import PAYMENT_METHOD_CC from './method-cc.png'
import MOCKUP_LOGIN_PAGE from './mockup-login-design.png'
import PAYMENT_QR_CODE from './payment-qr.png'
import PAYNOW from './paynow.png'
import REFERRAL_BANNER_IMG from './referral-banner-img.svg'
import REFERRAL_BANNER_IMG_MOBILE from './referral-banner-img-mobile.svg'
import REPAIR_DIAGNOSTIC from './repair-diagnostic.jpg'
import UPHOLSTERY_MATTRESS from './upholstery/mattress.png'
import UPHOLSTERY_MATTRESS_MOBILE from './upholstery/mattress-mobile.png'
import UPHOLSTERY_RUG from './upholstery/rug.png'
import UPHOLSTERY_RUG_MOBILE from './upholstery/rug-mobile.png'
import UPHOLSTERY_SOFA from './upholstery/sofa.png'
import UPHOLSTERY_SOFA_MOBILE from './upholstery/sofa-mobile.png'
import WHATSAPP_IMG from './whatsapp-img.png'
import DISCOUNT_PERCENTAGE_IMG from './discount-percentage.svg'
import DISCOUNT_FIXED_IMG from './discount-fixed.svg'
import WRAPPED_GIFT from './wrapped-gift.svg'
import THUMBNAILS_DEEP_EXTRACTION from './upholstery/ThumbnailsDeepExtraction.png'
import THUMBNAILS_UV_CLEANING from './upholstery/ThumbnailsUV-Cleaning.png'
import UPHOLSTERY_BABY_PRAM from './upholstery/baby-pram.png'
import UPHOLSTERY_BABY_PRAM_MOBILE from './upholstery/baby-pram-mobile.png'
import UPHOLSTERY_CHILD_SEAT from './upholstery/child-seat.png'
import UPHOLSTERY_CHILD_SEAT_MOBILE from './upholstery/child-seat-mobile.png'
import REFERRAL_GIFT from './referral-gift.svg'
import BG_PROMO_HOME_BEAUTY from './homeBeauty/bg-promo-home-beauty.svg'
import WA_LOGO_WHITE from './whatsapp-icon-logo-white.svg'
import GEL_MANIPEDI_PROMO from './homeBeauty/gel-manipedi-promo.png'
import LUCE_ICON from './luce-icon.svg'

export default {
  ERROR_ILLUSTRATION,
  WRAPPED_GIFT,
  DISCOUNT_PERCENTAGE_IMG,
  DISCOUNT_FIXED_IMG,
  PEDICURE_SERVICE,
  MANICURE_SERVICE,
  MANI_PEDI_SERVICE,
  BOOKING_SERVICE_HOME_BEAUTY,
  CALLUS_TREATMENT,
  EXTENDED_MESSAGE,
  EXTENSION_REMOVAL,
  GEL_REMOVAL,
  NAIL_EXTENSION,
  FRENCH_TIPS,
  NAIL_ART,
  GEL_PEDICURE,
  GEL_MANICURE,
  CLASSIC_PEDICURE,
  CLASSIC_MANICURE,
  GEL_MANI_CLASSIC_PEDI,
  GEL_MANI_PEDI,
  CLASSIC_MANI_PEDI,
  AIRCON_SERVICING,
  AIRCON_CARD,
  HOME_CLEANING_CARD,
  HOME_CLEANING_PACKAGE,
  CARPET_CLEANING,
  MARBLE_POLISHING,
  PAYMENT_METHOD_CC,
  PAYMENT_METHOD_BANKTRANSFER,
  PAYMENT_QR_CODE,
  PAYNOW,
  FEEDBACK_WIDGET_DONE,
  MOCKUP_LOGIN_PAGE,
  LUCE_LOGO_BLUE,
  WHATSAPP_IMG,
  LINK,
  REFERRAL_BANNER_IMG,
  REFERRAL_BANNER_IMG_MOBILE,
  BETA_APP_IMG,
  GOOGLE_PLAY,
  APP_STORE,
  GENERAL_SERVICING,
  CHEMICAL_WASH,
  CHEMICAL_OVERHAUL,
  CONDENSER_WASH,
  REPAIR_DIAGNOSTIC,
  AIRCON_WALL,
  AIRCON_CASSETE,
  AIRCON_DUCTED,
  AIRCON_PORTABLE,
  AIRCON_WINDOW,
  UPHOLSTERY_MATTRESS,
  UPHOLSTERY_RUG,
  UPHOLSTERY_SOFA,
  UPHOLSTERY_MATTRESS_MOBILE,
  UPHOLSTERY_RUG_MOBILE,
  UPHOLSTERY_SOFA_MOBILE,
  BOOKING_SERVICE_HOME,
  BOOKING_SERVICE_AIRCON,
  BOOKING_SERVICE_UPHOLSTERY,
  LUCE_APP_PREVIEW,
  LUCE_ICON,
  SHAREICON,
  THUMBNAILS_DEEP_EXTRACTION,
  THUMBNAILS_UV_CLEANING,
  UPHOLSTERY_BABY_PRAM,
  UPHOLSTERY_BABY_PRAM_MOBILE,
  UPHOLSTERY_CHILD_SEAT,
  UPHOLSTERY_CHILD_SEAT_MOBILE,
  REFERRAL_GIFT,
  BG_PROMO_HOME_BEAUTY,
  WA_LOGO_WHITE,
  GEL_MANIPEDI_PROMO,
}
