export default {
  env: 'production',
  sentryDsn:
    'https://d38a6f38e7d74fecaf1ae7698d8666a3@o933210.ingest.sentry.io/6413729',
  googleAnalytic: {
    gtmId: 'GTM-TQ6N3RQ',
    auth: 'PbPMtVj8JzDBHwDVQ4C8SA',
    preview: 'env-1',
  },
}
