import {
  ClientAccountTypeEnum,
  SignInProviderEnum,
} from '__generated__/globalTypes'
import { createSlice } from '@reduxjs/toolkit'
import { type Contact } from 'components/contactForm/ClientForm.container'
import { type Address } from 'components/contactForm/sections/FormAddressSection'

import { type RootState } from '../../../redux/slices'

export interface AccountTypeFormValue {
  accountType: ClientAccountTypeEnum
}

export interface AccountLoginFormValue {
  token?: string
  refreshToken?: string
  provider?: SignInProviderEnum
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  password: string
}

export interface AccountDetailsFormValue {
  accountName: string
  accountType: string
  accountPhoneNumber: string
  accountEmail: string
  contacts: Contact[]
  addresses: Address[]
}

type State = {
  signUpStep: number
  accountTypeValue: AccountTypeFormValue
  accountLoginValue: AccountLoginFormValue
  accountDetailsValue: AccountDetailsFormValue
  modalSigninSignup: boolean
  modalSigninSignupType: 'SIGN_IN' | 'SIGN_UP' | null
  modalVerifyPhoneNumber: boolean
  modalChangePhoneNumber: boolean
  modalAccountExists: boolean
  phoneNumber: string
}

export const initialState: State = {
  signUpStep: 1,
  accountTypeValue: {
    accountType: ClientAccountTypeEnum.INDIVIDUAL,
  },
  accountLoginValue: {
    provider: SignInProviderEnum.PASSWORD,
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    password: '',
  },
  accountDetailsValue: {
    accountName: '',
    accountType: '',
    accountEmail: '',
    accountPhoneNumber: '',
    contacts: [],
    addresses: [],
  },
  phoneNumber: '',
  modalSigninSignup: false,
  modalSigninSignupType: null,
  modalVerifyPhoneNumber: false,
  modalChangePhoneNumber: false,
  modalAccountExists: false,
}

const bookingSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setAccountTypeValue(
      state,
      {
        payload: { accountTypeValue },
      }: { payload: { accountTypeValue: AccountTypeFormValue } }
    ) {
      state.accountTypeValue = accountTypeValue
    },
    setAccountLoginValue(
      state,
      {
        payload: { accountLoginValue },
      }: { payload: { accountLoginValue: AccountLoginFormValue } }
    ) {
      state.accountLoginValue = accountLoginValue
    },
    setAccountDetailsValue(
      state,
      {
        payload: { accountDetailsValue },
      }: { payload: { accountDetailsValue: AccountDetailsFormValue } }
    ) {
      state.accountDetailsValue = accountDetailsValue
    },
    setSignUpStep(
      state,
      { payload: { signUpStep } }: { payload: { signUpStep: number } }
    ) {
      state.signUpStep = signUpStep
    },
    setModalSigninSignup(state, { payload }: { payload: boolean }) {
      state.modalSigninSignup = payload
    },
    setModalSigninSignUpType(
      state,
      { payload }: { payload: { type: 'SIGN_IN' | 'SIGN_UP' | null } }
    ) {
      state.modalSigninSignupType = payload.type
    },
    setModalVerifyPhoneNumber(state, { payload }: { payload: boolean }) {
      state.modalVerifyPhoneNumber = payload
    },
    setModalChangePhoneNumber(state, { payload }: { payload: boolean }) {
      state.modalChangePhoneNumber = payload
    },
    setModalAccountExists(state, { payload }: { payload: boolean }) {
      state.modalAccountExists = payload
    },
    setPhoneNumberSignUp(
      state,
      { payload }: { payload: { phoneNumber: string } }
    ) {
      state.phoneNumber = payload.phoneNumber
    },
    cleanupForm(state) {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value
      })
    },
  },
})

export const modalVerifyPhoneNumberSelector = (state: RootState) =>
  state.signupReducer.modalVerifyPhoneNumber

export const signUpStepSelector = (state: RootState) =>
  state.signupReducer.signUpStep

export const accountTypeValueSelector = (state: RootState) =>
  state.signupReducer.accountTypeValue

export const accountLoginValueSelector = (state: RootState) =>
  state.signupReducer.accountLoginValue

export const accountDetailsValueSelector = (state: RootState) =>
  state.signupReducer.accountDetailsValue

export const modalSigninSignupSelector = (state: RootState) =>
  state.signupReducer.modalSigninSignup

export const modalSigninSignupTypeSelector = (state: RootState) =>
  state.signupReducer.modalSigninSignupType

export const modalChangePhoneNumberSelector = (state: RootState) =>
  state.signupReducer.modalChangePhoneNumber

export const modalAccountExistsSelector = (state: RootState) =>
  state.signupReducer.modalAccountExists

export const phoneNumberSelector = (state: RootState) =>
  state.signupReducer.phoneNumber

export const { name, actions, reducer } = bookingSlice
