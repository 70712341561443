import { type ConfirmationType } from '__generated__/globalTypes'
import { ApolloError } from 'apollo-boost'
import { type ErrorResponse } from 'apollo-link-error'
import { compareAsc, compareDesc, parse } from 'date-fns'

import { development, production, staging } from '../config'
import { ShortDaysList } from '../ts/types/Days'
import { type PackageRequestTask } from '../ts/types/PackageRequest'
import { formatTimeAMPM } from './date'
import { getCookie } from './cookie'

type Env = {
  env: string
  sentryDsn: string
  googleAnalytic: {
    gtmId: string
    auth: string
    preview: string
  }
}

export function getEnv(): Env {
  switch (import.meta.env.VITE_REACT_APP_ENV) {
    case development.env:
      return development
    case staging.env:
      return staging
    case production.env:
      return production
    default:
      return development
  }
}

export function isLocal(): boolean {
  return import.meta.env.VITE_REACT_APP_ENV === 'local'
}

export function isStaging(): boolean {
  return import.meta.env.VITE_REACT_APP_ENV === 'staging'
}

export function isProduction(): boolean {
  return import.meta.env.VITE_REACT_APP_ENV === 'production'
}

export function getPhoneNumber(phoneNumber?: string) {
  // we actually use this because there is a phoneNumber which is optional,
  // for optional the phoneNumber value will not be validated when the value is smaller than 3,
  // so here we assume any phoneNumber value smaller than three will not be processed to the body request backend
  if (phoneNumber && phoneNumber.length > 3) {
    const formatNumber = ('' + phoneNumber).replace(/[()\s]|[- ]/g, '')
    return formatNumber
  }
  return ''
}

export const getErrorMessage = (err: unknown, fallback: string): string => {
  if (err instanceof ApolloError) {
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
      return err.graphQLErrors[0]!.message
    } else if (err.networkError) {
      return err.networkError.message
    }
  }

  if (err instanceof Error) {
    return err.message
  }

  return fallback
}

export const preventSilentSignin = (): void => {
  if (window.PasswordCredential || window.FederatedCredential) {
    navigator?.credentials?.preventSilentAccess()
  }
}

export const getRequiredConfirmations = (
  err: ErrorResponse | ApolloError
): ConfirmationType[] => {
  if (!err.graphQLErrors || err.graphQLErrors.length === 0) {
    return []
  }

  return (err.graphQLErrors[0]?.extensions?.required_confirmations ??
    []) as ConfirmationType[]
}

export const getAvatarLetter = (user: {
  firstName?: string
  lastName?: string
}): string =>
  [user.firstName, user.lastName]
    .map((name) => (name && name.length > 0 ? name[0] : ''))
    .join('')

export const getScrollBarWidth = (): number => {
  return window.innerWidth - document.documentElement.clientWidth
}

export const getCapitalCase = (word: string): string => {
  return word
    .toLocaleLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}

export const getTitleCase = (word: string): string => {
  return word
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const removeSpaces = (word: string): string => {
  return word.replace(/\s+/g, '')
}

export const handleWhatsappNumber = (number: string, template = '') => {
  const phone = number.replace(/\D/g, '')
  window.open('//wa.me/' + phone + '?text=' + template)
}

export const getServiceTypeCode = (serviceType: string): string => {
  return serviceType
    .split('_')
    .map((s) => s[0])
    .join('')
}

export const getKeyByValue = (obj: any, value: string): string | undefined => {
  return Object.keys(obj).find((key) => obj[key] === value)
}

export const getVisitsRange = (): {
  fromDate: Date
  toDate: Date
} => {
  const currentDate = new Date()
  const fromDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    currentDate.getDate()
  )
  const toDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    currentDate.getDate()
  )

  return {
    fromDate,
    toDate,
  }
}

export const sortByDate = (
  array: any[],
  key: string,
  order: 'asc' | 'desc' = 'asc'
): any[] => {
  const currentDate = new Date()
  if (order === 'desc') {
    return array.sort((a, b) =>
      compareDesc(
        parse(b[key], 'yyyy-MM-dd', currentDate),
        parse(a[key], 'yyyy-MM-dd', currentDate)
      )
    )
  }

  return array.sort((a, b) =>
    compareAsc(
      parse(b[key], 'yyyy-MM-dd', currentDate),
      parse(a[key], 'yyyy-MM-dd', currentDate)
    )
  )
}

export function removeFileExtension(fileName: string): string {
  return fileName.replace(/\.[^/.]+$/, '')
}

export function addTimestampToFileName(
  file: File,
  extension: string,
  type: string
): File {
  return new File(
    [file],
    `${removeFileExtension(file.name)}_${new Date().valueOf()}.${extension}`,
    { type }
  )
}

export function formatWorkerFirstLastName(
  firstName: string,
  lastName: string
): string {
  return `${firstName} ${lastName}`
}

export const setUnitNumber = (
  outputType: 'unitFloor' | 'apartmentNumber',
  unitNumber: string
): string | undefined => {
  if (!unitNumber) {
    return ''
  }

  if (!unitNumber.includes('#')) {
    if (outputType === 'unitFloor') {
      return unitNumber
    }
    if (outputType === 'apartmentNumber') {
      return 'NA'
    }
  } else if (unitNumber.includes('#')) {
    const splitedUnitNumber = unitNumber.split('#')[1]!
    if (splitedUnitNumber.includes('-')) {
      // #222-122
      const [unitFloor, apartmentNumber] = splitedUnitNumber.split('-')
      if (outputType === 'unitFloor') {
        return unitFloor
      }
      if (outputType === 'apartmentNumber') {
        return apartmentNumber
      }
    } else {
      // #222
      if (outputType === 'unitFloor') {
        return splitedUnitNumber
      }
      if (outputType === 'apartmentNumber') {
        return 'NA'
      }
    }
  }
}

export const getUnitNumber = (unitFloor?: string, apartmentNumber?: string) => {
  if (
    (unitFloor === '' && apartmentNumber === '') ||
    (unitFloor === '0' && apartmentNumber === '0')
  )
    return ''
  if (unitFloor === '') return `#NA-${apartmentNumber}`
  if (apartmentNumber === '') return `#${unitFloor}-NA`

  return `#${unitFloor}-${apartmentNumber}`
}

export const getFirstName = (fullName: string) => {
  const arrName = fullName.split(' ')
  return arrName.slice(0, -1).join(' ')
}

export const getLastName = (fullName: string) => {
  const arrName = fullName.split(' ')
  return arrName[arrName.length - 1]
}

export function getSchedulesToString(tasks: PackageRequestTask[]): string[] {
  return tasks.map((task): string => {
    const { day, startTime, endTime } = task
    const dayLabel = ShortDaysList[day]

    return `${dayLabel} (${formatTimeAMPM(startTime)} - ${formatTimeAMPM(
      endTime
    )})`
  })
}

export const capitalizeWord = (string: string): string => {
  return string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase()
  })
}

export const buildReferralInvitationMessage = (code: string): string => {
  const usingCodeHelpPageURL =
    'https://help.lucemg.com/referring-a-friend/using-a-referral-code'

  return [
    "Hey there! 👋🏻 I'd love to invite you to experience Luce's cleaning and maintenance services! They offer home and office cleaning, aircon servicing, upholstery deep cleaning, and even home beauty services for your manicure-pedicure needs! 💅✨\n\n",
    `Unlock $15 off on your first booking with my special code: ${code}. A treat just for you! 🎁\n\n`,
    `If you're wondering where to enter the code, you can check out this helpful guide: ${usingCodeHelpPageURL}`,
  ].join('\r')
}

export function getBlobFromBase64(
  fileUrl: string,
  type = 'data:application/octet-stream;base64'
): Blob {
  const fileContent = base64ToArrayBuffer(fileUrl)

  return new Blob([fileContent], {
    type,
  })
}

export function base64ToArrayBuffer(base64) {
  const binary_string = atob(base64)
  const len = binary_string.length
  const bytes = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }

  return bytes.buffer
}

export function getUTMFromQuery() {
  const utm = {
    source: getCookie('utm_source'),
    medium: getCookie('utm_medium'),
    campaign: getCookie('utm_campaign'),
    term: getCookie('utm_term'),
    content: getCookie('utm_content'),
  }

  // remove unecessary empty value
  return Object.fromEntries(
    Object.entries(utm).filter(([_, v]) => v != null || v != '')
  )
}

export function singaporeTaxCalculation(
  price: number,
  taxOnly?: boolean
): number {
  const sgTax = 0.09
  if (taxOnly) {
    return price * sgTax
  }
  return price * sgTax + price
}

export function getCalculateBookingHours(totalSessionDuration: number) {
  const hours = Math.floor(totalSessionDuration)
  return hours
}

export function getCalculateBookingMinutes(totalSessionDuration: number) {
  const minutes = Math.floor((totalSessionDuration % 1) * 60)
  return minutes
}

export function censorName(name: string) {
  const firstChar = name.charAt(0)
  const censoredName = firstChar + name.slice(1).replace(/./g, '*')

  return censoredName
}
