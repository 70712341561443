import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from '../../redux/slices'

export type PackageIssueType =
  | 'modifyPackage'
  | 'editAddressPackage'
  | 'terminatePackage'

type State = {
  packageIssueModal: string | null
  issueModalType: PackageIssueType | null
}

export const initialState: State = {
  packageIssueModal: null,
  issueModalType: null,
}

const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    setPackageIssueModal(
      state,
      action: PayloadAction<{ open: string | null; type?: PackageIssueType }>
    ) {
      const { open, type } = action.payload
      state.packageIssueModal = open
      state.issueModalType = type ?? null
    },
  },
})

export const packageIssueModalSelector = (state: RootState): string | null =>
  state.packageReducer.packageIssueModal

export const issueModalTypeSelector = (
  state: RootState
): PackageIssueType | null => state.packageReducer.issueModalType

export const { name, actions: packageActions, reducer } = packageSlice
