import Box from '@luce/ui-kit/components/base/Box'
import Typography from '@luce/ui-kit/components/base/Typography'
import Button from '@luce/ui-kit/components/button'
import Dialog from '@luce/ui-kit/components/organism/Dialog'
import { makeStyles, type Theme } from '@luce/ui-kit/themes/ThemeProvider'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import useAuth from '../../hooks/AuthHook'
import { actions } from './profile.slice'

interface LogOutModalProps {
  handleClose(): void
}

const useStyles = makeStyles((theme: Theme) => ({
  mDialogPaper: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(5),
      margin: theme.spacing(2),
    },
  },
  spacingBottom: {
    marginBottom: theme.spacing(2),
  },
  boxContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
}))

const LogoutModal: React.FC<LogOutModalProps> = (props) => {
  const { handleClose } = props
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { logout } = useAuth()

  const onLogOut = () => {
    dispatch(actions.setshowLogOutModal(false))
    logout()
    history.replace('/')
  }

  return (
    <Dialog
      className={classes.mDialogPaper}
      maxWidth="xs"
      open
      onClose={handleClose}
    >
      <Typography className={classes.spacingBottom} align="center" variant="h4">
        Log out
      </Typography>
      <Typography align="center" variant="body1">
        Are you sure you want to log out of Luce?
      </Typography>
      <Box className={classes.boxContainer}>
        <Button fullWidth variants="primary" onClick={onLogOut}>
          Log out
        </Button>
        <Button fullWidth variants="ghost" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  )
}
export default LogoutModal
