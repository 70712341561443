import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from '../../redux/slices'
import { type Client, type ClientPaymentMethod } from '../../ts/types/Client'

export interface PaymentMethodModalAttributes {
  title: string
  confirmLabel: string
}

type State = {
  client: Client | null
  showPaymentMethodModal: boolean
  showLogOutModal: boolean
  paymentMethodModalAttributes: PaymentMethodModalAttributes
  showAccountDeletionSuccessModal: boolean
  showAccountDeletionWarningModal: boolean
  showConfirmAccountDeletionModal: boolean
  activePaymentMethod: ClientPaymentMethod | null
  showAutoPayConfirmation: boolean
}

export const initialState: State = {
  client: null,
  showPaymentMethodModal: false,
  paymentMethodModalAttributes: {
    title: 'Payment Info',
    confirmLabel: 'Confirm',
  },
  showLogOutModal: false,
  showAccountDeletionSuccessModal: false,
  showAccountDeletionWarningModal: false,
  showConfirmAccountDeletionModal: false,
  activePaymentMethod: null,
  showAutoPayConfirmation: false,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setClient(state, { payload: { client } }: { payload: { client: Client } }) {
      state.client = client
    },

    setShowPaymentMethodModal(state, action: PayloadAction<boolean>) {
      state.showPaymentMethodModal = action.payload
    },

    setshowLogOutModal(state, action: PayloadAction<boolean>) {
      state.showLogOutModal = action.payload
    },

    setPaymentMethodModalAttributes(
      state,
      action: PayloadAction<PaymentMethodModalAttributes>
    ) {
      state.paymentMethodModalAttributes = action.payload
    },

    resetPaymentModal(state) {
      state.paymentMethodModalAttributes =
        initialState.paymentMethodModalAttributes
    },

    setActivePaymentMethod(
      state,
      action: PayloadAction<ClientPaymentMethod | null>
    ) {
      state.activePaymentMethod = action.payload
    },

    setShowAutoPayConfirmation(state, action: PayloadAction<boolean>) {
      state.showAutoPayConfirmation = action.payload
    },

    showAccountDeletionSuccessModal(state) {
      state.showAccountDeletionSuccessModal = true
    },

    hideAccountDeletionSuccessModal(state) {
      state.showAccountDeletionSuccessModal = false
    },

    showAccountDeletionWarningModal(state) {
      state.showAccountDeletionWarningModal = true
    },

    hideAccountDeletionWarningModal(state) {
      state.showAccountDeletionWarningModal = false
    },

    showConfirmAccountDeletionModal(state) {
      state.showConfirmAccountDeletionModal = true
    },

    hideConfirmAccountDeletionModal(state) {
      state.showConfirmAccountDeletionModal = false
    },
  },
})

export const clientSelector = (state: RootState) => state.profileReducer.client

export const showPaymentMethodModalSelector = (state: RootState) =>
  state.profileReducer.showPaymentMethodModal

export const showLogOutModalSelector = (state: RootState) =>
  state.profileReducer.showLogOutModal

export const paymentMethodModalAttributesSelector = (state: RootState) =>
  state.profileReducer.paymentMethodModalAttributes

export const activePaymentMethodSelector = (state: RootState) =>
  state.profileReducer.activePaymentMethod

export const showAutoPayConfirmation = (state: RootState) =>
  state.profileReducer.showAutoPayConfirmation

export const showAccountDeletionSuccessModalSelector = (state: RootState) =>
  state.profileReducer.showAccountDeletionSuccessModal

export const showAccountDeletionWarningModalSelector = (state: RootState) =>
  state.profileReducer.showAccountDeletionWarningModal

export const showConfirmAccountDeletionModalSelector = (state: RootState) =>
  state.profileReducer.showConfirmAccountDeletionModal

export const { name, actions, reducer } = profileSlice
