import React from 'react'
import Typography from '@luce/ui-kit/components/base/Typography'
import Images from 'assets/images/Images'
import Link from '@luce/ui-kit/components/atom/Link'
import BoxRow from '@luce/ui-kit/components/atom/BoxRow'
import {
  landscapeMobileScreen,
  useMediaQuery,
} from '../../booking/ui-component/MediaQueryResponsive'
import Box from '@luce/ui-kit/components/base/Box'

const SwitchBanner: React.FC = () => {
  const landscape = useMediaQuery(landscapeMobileScreen)
  console.log('🚀 ~ mobile:', landscape)
  const newLuceAppLink = import.meta.env.VITE_REACT_NEW_LUCE_APP_LINK

  if (!newLuceAppLink || import.meta.env.VITE_REACT_SHOW_SWITCH !== '1') {
    return null
  }

  return (
    <BoxRow
      alignItems={landscape ? 'flex-start' : 'center'}
      bgcolor="#F8FAFB"
      gap={2}
      py={2}
      px={3}
      position="sticky"
    >
      <Box mt={landscape ? 1 : 0}>
        <img src={Images.LUCE_ICON} width={16} alt="luce" />
      </Box>
      <BoxRow
        flex={1}
        flexDirection={landscape ? 'column' : 'row'}
        justifyContent="space-between"
        gap={2}
      >
        <Typography color="primary" variant="overline">
          Explore the New Luce! We’ve revamped the app with a fresh look and
          improved style. If you'd like, you can switch to the new version
          anytime!
        </Typography>
        <Link variant="subtitle2" href={newLuceAppLink} underline="none">
          Go to New Version
        </Link>
      </BoxRow>
    </BoxRow>
  )
}

export default SwitchBanner
