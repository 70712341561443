import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from '../../redux/slices'
import { type VisitDetail } from '../../ts/types/Visit'

export type VisitIssueType = 'changeVisitCleaner' | 'reportVisit'

type VisitModal =
  | 'rescheduleVisit'
  | 'issueVisit'
  | 'detailVisit'
  | 'cancelVisit'

export enum VisitTypeEnum {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
}

type State = {
  showLeaveReview: boolean
  visitId: string | null
  visitToken: string | null
  selectedVisit: VisitDetail | null
  visitModalType: VisitModal | null
  visitIssueType: VisitIssueType | null
  showReviewModal: boolean
  reportDateIssue: Date | null
  showSharerReferralModal: boolean
  visitType: VisitTypeEnum
}

const initialState: State = {
  showLeaveReview: false,
  visitId: null,
  visitToken: null,
  selectedVisit: null,
  visitModalType: null,
  visitIssueType: null,
  showReviewModal: false,
  reportDateIssue: null,
  showSharerReferralModal: false,
  visitType: VisitTypeEnum.UPCOMING,
}

const visitSlice = createSlice({
  name: 'visit',
  initialState,
  reducers: {
    setVisitId(
      state,
      { payload: { visitId } }: { payload: { visitId: string | null } }
    ) {
      state.visitId = visitId
      if (!visitId) {
        state.selectedVisit = null
      }
    },
    setVisitToken(
      state,
      { payload: { visitToken } }: { payload: { visitToken: string | null } }
    ) {
      state.visitToken = visitToken
    },
    setSelectedVisit(
      state,
      {
        payload: { selectedVisit },
      }: { payload: { selectedVisit: VisitDetail | null } }
    ) {
      state.selectedVisit = selectedVisit
    },
    setRescheduleModal(
      state,
      { payload }: PayloadAction<{ visitId: string | null }>
    ) {
      state.visitModalType = payload.visitId ? 'rescheduleVisit' : null
      state.visitId = payload.visitId ?? null
      if (!payload.visitId) {
        state.selectedVisit = null
      }
    },
    setVisitIssueModal(
      state,
      {
        payload,
      }: PayloadAction<{
        visitId: string | null
        visitIssueType?: VisitIssueType
        reportDate?: Date | null
      }>
    ) {
      state.visitModalType = payload.visitId ? 'issueVisit' : null
      state.visitId = payload.visitId ?? null
      state.visitIssueType = payload.visitIssueType ?? null
      state.reportDateIssue = payload.reportDate ?? null
    },
    setSkipVisitModal(
      state,
      {
        payload,
      }: PayloadAction<{
        visitId: string | null
      }>
    ) {
      state.visitModalType = payload.visitId ? 'cancelVisit' : null
      state.visitId = payload.visitId ?? null
    },
    setViewDetailModal(
      state,
      {
        payload,
      }: PayloadAction<{
        visitId: string | null
      }>
    ) {
      state.visitModalType = payload.visitId ? 'detailVisit' : null
      state.visitId = payload.visitId ?? null
    },
    setReviewModal(
      state,
      action: PayloadAction<{
        visitId: string
        isOpen: boolean
      }>
    ) {
      state.showLeaveReview = action.payload.isOpen
      state.showReviewModal = !!action.payload.visitId
      state.visitId = action.payload.visitId ?? null
    },
    setVisitType(
      state,
      action: PayloadAction<{
        visitType: VisitTypeEnum
      }>
    ) {
      state.visitType = action.payload.visitType
    },
    setShowSharerReferralModal(
      state,
      action: PayloadAction<{ isShow: boolean }>
    ) {
      state.showSharerReferralModal = action.payload.isShow
    },
  },
})

export const selectedVisitIdSelector = (state: RootState) =>
  state.visitReducer.visitId

export const selectedVisitTokenSelector = (state: RootState) =>
  state.visitReducer.visitToken

export const selectedVisitSelector = (state: RootState) =>
  state.visitReducer.selectedVisit

export const rescheduleModalSelector = (state: RootState) =>
  state.visitReducer.visitModalType === 'rescheduleVisit'

export const cancelVisitModalSelector = (state: RootState) =>
  state.visitReducer.visitModalType === 'cancelVisit'

export const visitDetailModalSelector = (state: RootState) =>
  state.visitReducer.visitModalType === 'detailVisit'

export const visitIssueModalSelector = (state: RootState) =>
  state.visitReducer.visitModalType === 'issueVisit'

export const visitIssueTypeSelector = (state: RootState) =>
  state.visitReducer.visitIssueType

export const reportDateIssueSelector = (state: RootState) =>
  state.visitReducer.reportDateIssue

export const showReviewModalSelector = (state: RootState) =>
  state.visitReducer.showReviewModal

export const showSharerReferralModalSelector = (state: RootState) =>
  state.visitReducer.showSharerReferralModal

export const showLeaveReviewSelector = (state: RootState) =>
  state.visitReducer.showLeaveReview

export const visitTypeSelector = (state: RootState): VisitTypeEnum =>
  state.visitReducer.visitType

export const { name, actions: visitActions, reducer } = visitSlice
