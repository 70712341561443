import { createSlice } from '@reduxjs/toolkit'

import { type RootState } from './index'

const initialState = {
  modal: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state) {
      state.modal = true
    },
    hideModal(state) {
      state.modal = false
    },
  },
})

export const modalSelector = (state: RootState) => state.modalReducer.modal

export const { name, actions, reducer } = modalSlice
