import { combineReducers } from '@reduxjs/toolkit'

import { reducer as bookingReducer } from '../../components/booking/booking.slice'
import { reducer as signupReducer } from './../../components/auth/signup/signup.slice'
import { reducer as invoiceReducer } from './../../components/invoice/invoice.slice'
import { reducer as voucherReducer } from './../../components/invoice/voucher.slice'
import { reducer as creditReducer } from './../../components/invoice/credit.slice'
import { reducer as packageReducer } from './../../components/package/package.slice'
import { reducer as profileReducer } from './../../components/profile/profile.slice'
import { reducer as visitReducer } from './../../components/visit/visit.slice'
import { reducer as rewardReducer } from '../../components/reward/reward.slice'
import { reducer as appReducer } from './appSlice'
import { reducer as modalReducer } from './modalSlice'

const rootReducer = combineReducers({
  appReducer,
  modalReducer,
  signupReducer,
  bookingReducer,
  invoiceReducer,
  profileReducer,
  visitReducer,
  packageReducer,
  voucherReducer,
  creditReducer,
  rewardReducer,
})
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
