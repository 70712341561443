import { createSlice } from '@reduxjs/toolkit'

// TODO: will remove this slice later
const initialState = {}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
})

export const { name, actions, reducer } = appSlice
