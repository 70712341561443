import {
  type ClientFindBookingSchedule_clientFindBookingSchedule_afternoon_worker_workerContracts as WorkerContractsAfternoon,
  type ClientFindBookingSchedule_clientFindBookingSchedule_evening_worker_workerContracts as WorkerContractsEvening,
  type ClientFindBookingSchedule_clientFindBookingSchedule_morning_worker_workerContracts as WorkerContractsMorning,
} from '__generated__/ClientFindBookingSchedule'
import { PackageServiceTypeEnum } from '__generated__/globalTypes'
import { type IconName } from '@luce/ui-kit/components/icons/Icons'
import Images from 'assets/images/Images'
import {
  AvailableHomeBeautyService,
  HomeBeautyBookingDetails,
} from 'components/booking/booking.slice'

export type HomeBeautyServices = {
  serviceType: PackageServiceTypeEnum
  title: string
  subTitle: string
  iconName: IconName
  imgUrl: string
  serviceTitle: string
}

export type HomeBeautyDetail = {
  imgUrl: string
  title: string
  description: string
  imgCarousel?: string[]
  maxItem?: number
  sortOrder?: number
  mostPopular?: boolean
  prefix?: string
  undefinedCode?: boolean
}

export type WorkerPortfolio = {
  id: string
  fileName: string
  imgUrl: string
}

export const beautyMainService =
  PackageServiceTypeEnum.MANICURE &&
  PackageServiceTypeEnum.PEDICURE &&
  PackageServiceTypeEnum.COMBO

export const beautyAddOnService = PackageServiceTypeEnum.ADD_ON

export const beautyUpgradeService = PackageServiceTypeEnum.UPGRADE

export const MINIMUM_FREE_GEL_REMOVAL = 5

export const GEL_REMOVAL_CODE = 'HBNAGR'
export const NAIL_ART_PACKAGE_CODE = 'HBNANA'

export const homeBeautyDetailMapping = (code: string): HomeBeautyDetail => {
  switch (code) {
    case 'HBNCCMP':
      return {
        imgUrl: Images.CLASSIC_MANI_PEDI,
        title: 'Classic ManiPedi',
        description:
          'Enjoy timeless elegance with our classic manicure and pedicure for the perfect polished look and well-groomed hands and feet. Regular polish included.',
      }
    case 'HBNCGMP':
      return {
        imgUrl: Images.GEL_MANI_PEDI,
        title: 'Gel ManiPedi',
        description:
          'Indulge in enduring beauty with our gel polish service, cured flawlessly under LED light for up to 3 weeks of chip-free radiance. Basic cleaning included.',
      }
    case 'HBNCGMCP':
      return {
        imgUrl: Images.GEL_MANI_CLASSIC_PEDI,
        title: 'Gel Mani + Classic Pedi',
        description:
          'Elevate your ManiPedi experience with a durable gel manicure and classic pedicure care. ',
      }
    case 'HBNCCMA':
      return {
        imgUrl: Images.CLASSIC_MANICURE,
        title: 'Classic Manicure',
        description:
          'Elegant, clean nails, expertly shaped, polished and pampered for a classic and fresh look. Regular polish and short hand massage included.',
      }
    case 'HBNCCPE':
      return {
        imgUrl: Images.CLASSIC_PEDICURE,
        title: 'Classic Pedicure',
        description:
          'Step into relaxation with our classic pedicure. Enjoy expert nail care and polish finishing for soft and beautiful feet. Regular polish and short foot massage included.',
      }
    case 'HBNCGMA':
      return {
        imgUrl: Images.GEL_MANICURE,
        title: 'Gel Manicure',
        description:
          'Elevate your nails with our gel manicure for a long-lasting, chip-resistant, and glossy finish for beautiful nails.',
      }
    case 'HBNCGPE':
      return {
        imgUrl: Images.GEL_PEDICURE,
        title: 'Gel Pedicure',
        description:
          'Elevate your nails with our gel pedicure for a long-lasting, chip-resistant, and glossy finish for beautiful nails.',
        sortOrder: 1,
      }
    case 'HBNANA':
      return {
        imgUrl: Images.NAIL_ART,
        title: 'Nail Art (per nail)',
        description:
          'Express yourself in creative, personalized manicures and enjoy our nail art add on!',
        maxItem: 20,
        prefix: '/nail',
      }
    case 'HBNAFT':
      return {
        imgUrl: Images.FRENCH_TIPS,
        title: 'French Tips',
        description:
          'Perfect for anyone looking for a stylish and timeless design. It gives your manicure that extra touch of sophistication.',
        maxItem: 2,
        prefix: '/10 nails',
      }
    case 'HBNAEX':
      return {
        imgUrl: Images.NAIL_EXTENSION,
        title: 'Nail Extension',
        description:
          'Elevate your manicure with long-lasting nail extensions and get an even more glamorous manicure.',
        maxItem: 2,
        prefix: '/10 nails',
      }
    case 'HBNAGR':
      return {
        imgUrl: Images.GEL_REMOVAL,
        title: 'Gel Removal',
        description:
          'Seamless and precise gel removal for fresh, healthy nails.',
        maxItem: 2,
        sortOrder: 3,
        prefix: '/10 nails',
      }
    case 'HBNAER':
      return {
        imgUrl: Images.EXTENSION_REMOVAL,
        title: 'Extension removal',
        description: `Gentle and precise nail extension removal, restoring your natural nails' beauty.`,
        maxItem: 2,
        sortOrder: 4,
        prefix: '/10 nails',
      }
    case 'HBNAEM':
      return {
        imgUrl: Images.EXTENDED_MESSAGE,
        title: 'Extended Massage',
        description:
          'Rejuvenate and experience silky, smooth, callus-free hands or feet with this treatment.',
        imgCarousel: [Images.EXTENDED_MESSAGE],
        sortOrder: 1,
        mostPopular: true,
      }
    case 'HBNACT':
      return {
        imgUrl: Images.CALLUS_TREATMENT,
        title: 'Callus Treatment',
        description:
          'Indulge in post-manicure bliss and extend your hand massage time.',
        imgCarousel: [Images.CALLUS_TREATMENT],
        sortOrder: 2,
        mostPopular: true,
      }
    case 'HBNDFGR':
      return {
        imgUrl: Images.GEL_REMOVAL,
        title: 'Free Gel Removal',
        description:
          'Seamless and precise gel removal for fresh, healthy nails.',
      }
    default:
      return {
        imgUrl: '-',
        title: '-',
        description: '-',
        undefinedCode: true,
      }
  }
}

export const getWorkerPortfolios = (
  workerContracts:
    | WorkerContractsAfternoon[]
    | WorkerContractsEvening[]
    | WorkerContractsMorning[]
): WorkerPortfolio[] => {
  const workerPortfolios = workerContracts.reduce<WorkerPortfolio[]>(
    (workerPortfolios, contracts) => {
      const itemPortfolios: WorkerPortfolio[] = contracts.portfolios
        ? contracts.portfolios.map((itemPortfolio) => {
            return {
              id: itemPortfolio.id,
              fileName: itemPortfolio.filename,
              imgUrl: itemPortfolio.downloadUrl,
            }
          })
        : []

      return [...workerPortfolios, ...itemPortfolios]
    },
    []
  )

  return workerPortfolios
}

export const getFreeGelRemovalTotalItem = (
  lineItems: Record<AvailableHomeBeautyService, HomeBeautyBookingDetails[]>
): number => {
  const items = Object.values(lineItems).map((unitsDetail) =>
    unitsDetail.filter(
      (item) => item.serviceType === PackageServiceTypeEnum.DISCOUNT
    )
  )

  return items.flat()[0]?.totalItem ?? 0
}

export const getGelRemovalTotalItem = (
  lineItems: Record<AvailableHomeBeautyService, HomeBeautyBookingDetails[]>
): number => {
  const items = Object.entries(lineItems).map(([_unit, unitsDetail]) =>
    unitsDetail.filter((item) => item.packageCode === GEL_REMOVAL_CODE)
  )

  return items.flat()[0]?.totalItem ?? 0
}

export const isAdjustmentItemsIsEmpty = (
  lineItems: Record<AvailableHomeBeautyService, HomeBeautyBookingDetails[]>
): boolean => {
  const items = Object.values(lineItems).flatMap((unitsDetail) =>
    unitsDetail.filter(
      (item) =>
        item.serviceType === PackageServiceTypeEnum.UPGRADE ||
        (item.serviceType === PackageServiceTypeEnum.ADD_ON &&
          item.packageCode !== GEL_REMOVAL_CODE)
    )
  )

  return items.length === 0
}

export const getSelectedTotalItem = (
  lineItems: Record<AvailableHomeBeautyService, HomeBeautyBookingDetails[]>,
  packageCode: string
): number => {
  const currentLineItem = Object.values(lineItems).flatMap((unitsDetail) =>
    unitsDetail
      .filter((item) => item.packageCode === packageCode)
      .map((item) => item.totalItem)
  )

  const totalItem = currentLineItem.reduce(
    (accumulator, value) => accumulator + value,
    0
  )

  return totalItem
}

export const isGelRemovalPackage = (
  selectedHomeBeautyDetail: HomeBeautyBookingDetails
): boolean => {
  const selectedPackageCode = selectedHomeBeautyDetail.packageCode

  if (selectedPackageCode === GEL_REMOVAL_CODE) {
    return true
  }
  return false
}
