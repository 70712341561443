import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from '../../redux/slices'

type State = {
  showApplyCreditModal: boolean
  invoiceId: string
  referralCode: string | undefined
}

export const initialState: State = {
  showApplyCreditModal: false,
  invoiceId: '',
  referralCode: '',
}

const creditSlice = createSlice({
  name: 'credit',
  initialState,
  reducers: {
    setShowModal(state, action: PayloadAction<boolean>) {
      state.showApplyCreditModal = action.payload
    },
    setInvoiceId(state, action: PayloadAction<string>) {
      state.invoiceId = action.payload
    },
    setReferralCode(
      state,
      {
        payload: { referralCode },
      }: { payload: { referralCode: string | undefined } }
    ) {
      state.referralCode = referralCode
    },
  },
})

export const invoiceIdSelector = (state: RootState): string =>
  state.creditReducer.invoiceId

export const showApplyCreditModalSelector = (state: RootState): boolean =>
  state.creditReducer.showApplyCreditModal

export const referralCodeSelector = (state: RootState) =>
  state.creditReducer.referralCode

export const { name, actions, reducer } = creditSlice
