export default {
  env: 'staging',
  sentryDsn:
    'https://f29c4b1363094242948852a7bafb2013@o933210.ingest.sentry.io/6417079',
  googleAnalytic: {
    gtmId: 'GTM-TQ6N3RQ',
    auth: 'eKZDP93BInjYNvfcpgdEcA',
    preview: 'env-4',
  },
}
