import { configureStore } from '@reduxjs/toolkit'
import { type StoreEnhancer } from 'redux'
import { logger } from 'redux-logger'
import { sentryReduxEnhancer } from 'services/sentry'

import appReducer from './slices/'

export default function configureAppStore(initialState = {}) {
  const middlewares = [logger]

  const enhancers: StoreEnhancer[] = [sentryReduxEnhancer]
  const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      ...middlewares,
    ],
    preloadedState: initialState,
    devTools: import.meta.env.VITE_REACT_APP_ENV !== 'production',
    enhancers,
  })

  return store
}

export const store = configureAppStore()
