import CircularProgress from '@luce/ui-kit/components/atom/CircularProgress'
import Box from '@luce/ui-kit/components/base/Box'

const Loader = () => {
  return (
    <Box>
      <div style={{ textAlign: 'center', margin: 16 }}>
        <CircularProgress />
      </div>
    </Box>
  )
}

export default Loader
