import Box from '@luce/ui-kit/components/base/Box'
import Color from '@luce/ui-kit/components/base/Color'
import Typography from '@luce/ui-kit/components/base/Typography'
import { makeStyles, type Theme } from '@luce/ui-kit/themes/ThemeProvider'

const useStyles = makeStyles((theme: Theme) => ({
  text404: {
    color: Color.primary[500],
    fontSize: 120,
    [theme.breakpoints.down('xs')]: { fontSize: 52 },
  },
  svgMaintenance: {
    width: 268,
    [theme.breakpoints.down('xs')]: { width: 120 },
    height: 187,
  },
  textCouldntFind: {
    [theme.breakpoints.down('xs')]: { fontSize: 24, marginBottom: 16 },
  },
  boxWrapper: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 48,
      paddingBottom: 48,
      justifyContent: 'flex-start',
    },
  },
  boxFooter: {
    [theme.breakpoints.down('xs')]: { display: 'none' },
  },
}))

const MaintenancePage = () => {
  const styles = useStyles()

  return (
    <Box
      className={styles.boxWrapper}
      bgcolor="#fff"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 64px)"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box zIndex={2}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <img
            src="/maintenance.svg"
            alt="maintenance"
            className={styles.svgMaintenance}
          />
        </Box>
        <Box padding={4} margin="auto">
          <Typography
            className={styles.textCouldntFind}
            color="primary"
            variant="h1"
            align="center"
          >
            We are Under Maintenance
          </Typography>
          <Typography color="primary" variant="subtitle1" align="center">
            We are improving our website to serve you better.We should be back
            shortly.
          </Typography>
          <Typography color="primary" variant="subtitle1" align="center">
            Thank you for your patience.
          </Typography>
        </Box>
      </Box>
      <Box
        className={styles.boxFooter}
        position="absolute"
        left={0}
        right={0}
        bottom={0}
        width="100vw"
        height="30vh"
        bgcolor="#f4fafd"
      />
    </Box>
  )
}

export default MaintenancePage
