export default {
  env: 'development',
  sentryDsn:
    'https://f52d6b8f71b0425e9e6141152df5df60@o933210.ingest.sentry.io/6417096',
  googleAnalytic: {
    gtmId: 'GTM-TQ6N3RQ',
    auth: 'xneDwFCeX_2r3NJyhE7BdQ',
    preview: 'env-3',
  },
}
