import { type User } from 'context/AuthContext'
import {
  clearMonitoringUserScope,
  setMonitoringUserScope,
} from 'services/sentry'

export interface UserSession {
  user?: User | null
}

export const userSessionKey = 'USER_DATA'

const restoreSession = (): UserSession => {
  const data = window.localStorage.getItem(userSessionKey)
  const userData: User | null = data ? (JSON.parse(data) as User) : null
  console.log(
    `Restoring User Session, user data: ${userData ? 'exists' : 'empty'}`
  )

  if (userData) {
    setMonitoringUserScope({
      clientId: userData.id,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      fullName: `${userData.firstName} ${userData.lastName}`,
    })
  }

  return {
    user: userData
      ? {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          avatarUrl: userData.avatarUrl,
          phoneNumber: userData.phoneNumber,
          email: userData.email,
        }
      : null,
  }
}

let session: UserSession | undefined

const logout = (): UserSession => {
  window.localStorage.removeItem(userSessionKey)
  session = restoreSession()
  clearMonitoringUserScope()
  return session
}

const login = (user: User): UserSession => {
  console.log('Write session to storage')
  const userData = JSON.stringify(user)
  window.localStorage.setItem(userSessionKey, userData)
  session = {
    user: Object.assign({}, user),
  }
  return session
}

const currentSession = (): UserSession => {
  session = restoreSession()
  return session
}

export default {
  currentSession,
  login,
  logout,
}
