import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Invoice } from 'ts/types/Invoice'

import { type RootState } from '../../redux/slices'

type State = {
  invoiceModal: Invoice | null
  invoicePaidId: string | null
  enableAutoPay: boolean
  isConfirmInvoice: boolean
}

export const initialState: State = {
  invoiceModal: null,
  invoicePaidId: null,
  enableAutoPay: true,
  isConfirmInvoice: false,
}

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceModal(
      state,
      action: PayloadAction<{ invoiceModal: Invoice | null }>
    ) {
      state.invoiceModal = action.payload.invoiceModal
    },

    setInvoiceToBePaid(
      state,
      action: PayloadAction<{ invoicePaidId: string | null }>
    ) {
      state.invoicePaidId = action.payload.invoicePaidId
    },

    setEnableAutoPay(state, action: PayloadAction<{ enableAutoPay: boolean }>) {
      state.enableAutoPay = action.payload.enableAutoPay
    },

    setIsConfirmInvoice(
      state,
      action: PayloadAction<{ isConfirmInvoice: boolean }>
    ) {
      state.isConfirmInvoice = action.payload.isConfirmInvoice
    },
  },
})

export const invoiceModalSelector = (state: RootState): Invoice | null =>
  state.invoiceReducer.invoiceModal

export const invoiceToBePaidSelector = (state: RootState): string | null =>
  state.invoiceReducer.invoicePaidId

export const enableAutoPaySelector = (state: RootState): boolean =>
  state.invoiceReducer.enableAutoPay

export const isConfirmInvoiceSelector = (state: RootState): boolean =>
  state.invoiceReducer.isConfirmInvoice

export const { name, actions, reducer } = invoiceSlice
