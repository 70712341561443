import { type Client_client_addresses } from '__generated__/Client'
import {
  PackageDepartmentEnum,
  PackageServiceTypeEnum,
  PackageUnitTypeEnum,
  UnitSizeEnum,
  PackageDetailSessionEnum,
} from '__generated__/globalTypes'
import { type ReservationById_reservationById } from '__generated__/ReservationById'
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import { intervalToDuration } from 'date-fns'
import { type ShortDay } from 'ts/types/Days'
import { type PackageDetail } from 'ts/types/Package'
import { parseDateTime } from 'utils/date'

import { type ClientRescheduleVisitWithToken_clientRescheduleVisitWithToken_visit } from '../../__generated__/ClientRescheduleVisitWithToken'
import { type RootState } from '../../redux/slices'
import { type BookingType } from './BookingPage.container'
import { type WorkerPortfolio } from './formWizard/homeBeautyComponents/homeBeautyHelper'
import {
  getRepeatEveryFrequency,
  groupTimeSlotByDate,
  PackageFrequencyGroupEnum,
} from './lib'
import { SERVICE_TYPE_UNIT_TYPES } from './ui-component/AirconServiceUnitInput'

export enum BookingStepEnum {
  SELECT_SERVICE = 'SELECT_SERVICE',
  CHOOSE_ITEMS = 'CHOOSE_ITEMS',
  BOOKING_DETAIL = 'BOOKING_DETAIL',
  SELECT_SLOT = 'SELECT_SLOT',
  SERVICE_INFO = 'SERVICE_INFO',
  CONFIRMATION = 'CONFIRMATION',
  COMPLETE_BOOKING = 'COMPLETE_BOOKING',
  SELECT_BEAUTY_SERVICE = 'SELECT_BEAUTY_SERVICE',
}

export type BookingDetailFormValues = {
  frequency: PackageFrequencyGroupEnum
  postalCode: string
  fullAddress?: string
  addressId: string
}

export type ServiceInfoFormValues = {
  contactId: string
  name: string
  phoneNumber: string
  email: string
  addressId: string
  postalCode: string
  accessMode: string
  accessInstructions: string
  packageNotes: string
}

export interface AccountDetailsFormValues {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  postalCode: string
  address: string
  unitFloor: string
  apartmentNumber: string
}

export interface AccountSetupFormValues {
  email: string
  password: string
}

export type Cleaner = {
  id: string
  name: string
  completedJobs: number
}

export type TimeSlot = {
  id: string
  slotId: string
  date: string
  day: ShortDay
  partOfDay: 'Morning' | 'Evening' | 'Afternoon'
  startTime: string
  endTime: string
  averageTravelTime: number
  workerId: string
  discount?: number
  sessionValue?: number
  rateValue?: number
  workerName?: string
  avatarUrl?: string
  workerRating?: number
  portfolios?: WorkerPortfolio[]
}

export type AppliedPromo = {
  appliedPromoCode: string
  computedDiscount: number
}

export type AvailableClientAirconService =
  | PackageServiceTypeEnum.GENERAL_SERVICING
  | PackageServiceTypeEnum.CHEMICAL_WASH
  | PackageServiceTypeEnum.CHEMICAL_OVERHAUL
  | PackageServiceTypeEnum.CONDENSER_WASH
  | PackageServiceTypeEnum.REPAIR_DIAGNOSTIC
  | PackageServiceTypeEnum.JET_WASH

export type AvailableClientUpholsteryItem =
  | PackageUnitTypeEnum.MATTRESS
  | PackageUnitTypeEnum.SOFA
  | PackageUnitTypeEnum.RUG
  | PackageUnitTypeEnum.BABY_PRAM
  | PackageUnitTypeEnum.CHILD_SEAT

export type AvailableHomeBeautyService =
  | PackageServiceTypeEnum.COMBO
  | PackageServiceTypeEnum.MANICURE
  | PackageServiceTypeEnum.PEDICURE
  | PackageServiceTypeEnum.UPGRADE
  | PackageServiceTypeEnum.ADD_ON
  | PackageServiceTypeEnum.DISCOUNT

export const DEFAULT_UPHOLSTERY_UNITS_QTY: Record<
  AvailableClientUpholsteryItem,
  number
> = {
  [PackageUnitTypeEnum.MATTRESS]: 0,
  [PackageUnitTypeEnum.RUG]: 0,
  [PackageUnitTypeEnum.SOFA]: 0,
  [PackageUnitTypeEnum.BABY_PRAM]: 0,
  [PackageUnitTypeEnum.CHILD_SEAT]: 0,
}

export const DEFAULT_UPHOLSTERY_UNITS_DETAIL: Record<
  AvailableClientUpholsteryItem,
  UnitDetailType[]
> = {
  [PackageUnitTypeEnum.MATTRESS]: [],
  [PackageUnitTypeEnum.RUG]: [],
  [PackageUnitTypeEnum.SOFA]: [],
  [PackageUnitTypeEnum.BABY_PRAM]: [],
  [PackageUnitTypeEnum.CHILD_SEAT]: [],
}

export const DEFAULT_HOME_BEAUTY_UNITS_DETAIL: Record<
  AvailableHomeBeautyService,
  HomeBeautyBookingDetails[]
> = {
  [PackageServiceTypeEnum.ADD_ON]: [],
  [PackageServiceTypeEnum.COMBO]: [],
  [PackageServiceTypeEnum.MANICURE]: [],
  [PackageServiceTypeEnum.PEDICURE]: [],
  [PackageServiceTypeEnum.UPGRADE]: [],
  [PackageServiceTypeEnum.DISCOUNT]: [],
}

export interface UnitDetailType {
  packageDetail: PackageDetail | null
  unitSize: UnitSizeEnum | null
  serviceType: PackageServiceTypeEnum | null
}

export interface HomeBeautyBookingDetails {
  packageId: string
  packageCode: string
  unitValue: number
  serviceType: PackageServiceTypeEnum
  duration: number
  units: number
  totalItem: number
  freeGelRemoval: boolean
}

type State = {
  bookingStepKey: BookingStepEnum
  postBookingDepartment: PackageDepartmentEnum | null
  department: PackageDepartmentEnum
  serviceTypes: PackageServiceTypeEnum[]
  homeBeautyServiceOptions: PackageDetail[]
  homeBeautyUnitsDetail: Record<
    AvailableHomeBeautyService,
    HomeBeautyBookingDetails[]
  >
  upholsteryUnitsQty: Record<AvailableClientUpholsteryItem, number>
  upholsteryUnitsDetail: Record<AvailableClientUpholsteryItem, UnitDetailType[]>
  bookingDetailForm: BookingDetailFormValues
  serviceInfoForm: ServiceInfoFormValues
  selectedWorkerIds: string[] | null
  modalNewAddress: boolean
  modalNewContact: boolean
  isWorkerPortfolio: boolean
  modalChooseCleaners: boolean
  modalUpsellRecurring: boolean
  availableSlots: TimeSlot[]
  selectedSlot: TimeSlot | null
  packageDetails: PackageDetail[]
  addressDetail: Client_client_addresses | null
  reservation: ReservationById_reservationById | null
  promo: AppliedPromo
  reservationExpiredTime: string | null
  rescheduledVisit: ClientRescheduleVisitWithToken_clientRescheduleVisitWithToken_visit | null
  signUpForBooking: boolean
  signInForBooking: boolean
}

export const HOME_CLEANING_BOOKING_ORDER = [
  BookingStepEnum.BOOKING_DETAIL,
  BookingStepEnum.SELECT_SLOT,
  BookingStepEnum.SERVICE_INFO,
  BookingStepEnum.CONFIRMATION,
  BookingStepEnum.COMPLETE_BOOKING,
]

export const AIRCON_BOOKING_ORDER = [
  BookingStepEnum.SELECT_SERVICE,
  BookingStepEnum.BOOKING_DETAIL,
  BookingStepEnum.SELECT_SLOT,
  BookingStepEnum.SERVICE_INFO,
  BookingStepEnum.CONFIRMATION,
  BookingStepEnum.COMPLETE_BOOKING,
]

export const CARPET_UPHOLSTERY_BOOKING_ORDER = [
  BookingStepEnum.CHOOSE_ITEMS,
  BookingStepEnum.BOOKING_DETAIL,
  BookingStepEnum.SELECT_SLOT,
  BookingStepEnum.SERVICE_INFO,
  BookingStepEnum.CONFIRMATION,
  BookingStepEnum.COMPLETE_BOOKING,
]

export const HOME_BEAUTY_BOOKING_ORDER = [
  BookingStepEnum.SELECT_BEAUTY_SERVICE,
  BookingStepEnum.BOOKING_DETAIL,
  BookingStepEnum.SELECT_SLOT,
  BookingStepEnum.SERVICE_INFO,
  BookingStepEnum.CONFIRMATION,
  BookingStepEnum.COMPLETE_BOOKING,
]

export const BOOKING_ORDER: Record<PackageDepartmentEnum, BookingStepEnum[]> = {
  [PackageDepartmentEnum.HOME_CLEANING]: HOME_CLEANING_BOOKING_ORDER,
  [PackageDepartmentEnum.AIRCON]: AIRCON_BOOKING_ORDER,
  [PackageDepartmentEnum.CARPET_UPHOLSTERY]: CARPET_UPHOLSTERY_BOOKING_ORDER,
  [PackageDepartmentEnum.HOME_BEAUTY]: HOME_BEAUTY_BOOKING_ORDER,
  [PackageDepartmentEnum.MASSAGE]: [],
  [PackageDepartmentEnum.BABYSITTER]: [],
  [PackageDepartmentEnum.HANDYMAN]: [],
  [PackageDepartmentEnum.CAR_WASH]: [],
  [PackageDepartmentEnum.OFFICE_CLEANING]: [],
}

const findStepBooking = (
  department: PackageDepartmentEnum,
  currentStep: BookingStepEnum,
  type: 'prev' | 'next'
): BookingStepEnum => {
  const steps = BOOKING_ORDER[department]
  const currentIndex = steps.findIndex((step) => step === currentStep)

  return type === 'next' ? steps[currentIndex + 1] : steps[currentIndex - 1]
}

export const initialState: State = {
  bookingStepKey: BookingStepEnum.SELECT_SERVICE,
  department: PackageDepartmentEnum.HOME_CLEANING,
  postBookingDepartment: null,
  serviceTypes: [],
  homeBeautyUnitsDetail: DEFAULT_HOME_BEAUTY_UNITS_DETAIL,
  upholsteryUnitsQty: DEFAULT_UPHOLSTERY_UNITS_QTY,
  upholsteryUnitsDetail: DEFAULT_UPHOLSTERY_UNITS_DETAIL,
  bookingDetailForm: {
    frequency: PackageFrequencyGroupEnum.AD_HOC,
    postalCode: '',
    addressId: '',
  },
  homeBeautyServiceOptions: [],
  serviceInfoForm: {
    contactId: '',
    name: '',
    phoneNumber: '',
    email: '',
    addressId: '',
    postalCode: '',
    accessMode: '',
    accessInstructions: '',
    packageNotes: '',
  },
  selectedWorkerIds: null,
  modalNewAddress: false,
  modalNewContact: false,
  isWorkerPortfolio: false,
  modalChooseCleaners: false,
  modalUpsellRecurring: false,
  availableSlots: [],
  selectedSlot: null,
  packageDetails: [],
  addressDetail: null,
  reservation: null,
  promo: {
    appliedPromoCode: '',
    computedDiscount: 0,
  },
  reservationExpiredTime: null,

  rescheduledVisit: null,
  signUpForBooking: false,
  signInForBooking: false,
}

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    initBookingType(state, { payload }: { payload: BookingType }) {
      if (payload === 'aircon') {
        state.bookingStepKey = BookingStepEnum.SELECT_SERVICE
        state.department = PackageDepartmentEnum.AIRCON
        state.serviceTypes = []
        state.bookingDetailForm = {
          frequency: PackageFrequencyGroupEnum.QUARTERLY,
          postalCode: '',
          addressId: '',
        }
      } else if (payload === 'upholstery') {
        state.bookingStepKey = BookingStepEnum.CHOOSE_ITEMS
        state.department = PackageDepartmentEnum.CARPET_UPHOLSTERY
        state.serviceTypes = []
        state.upholsteryUnitsQty = DEFAULT_UPHOLSTERY_UNITS_QTY
        state.upholsteryUnitsDetail = DEFAULT_UPHOLSTERY_UNITS_DETAIL
        state.bookingDetailForm = {
          frequency: PackageFrequencyGroupEnum.AD_HOC,
          postalCode: '',
          addressId: '',
        }
      } else if (payload === 'beauty') {
        state.bookingStepKey = BookingStepEnum.SELECT_BEAUTY_SERVICE
        state.department = PackageDepartmentEnum.HOME_BEAUTY
        state.homeBeautyUnitsDetail = DEFAULT_HOME_BEAUTY_UNITS_DETAIL
        state.homeBeautyServiceOptions = []
        state.bookingDetailForm = {
          frequency: PackageFrequencyGroupEnum.AD_HOC,
          postalCode: '',
          addressId: '',
        }
      } else {
        state.bookingStepKey = BookingStepEnum.BOOKING_DETAIL
        state.department = PackageDepartmentEnum.HOME_CLEANING
        state.serviceTypes = [PackageServiceTypeEnum.HOME_CLEANING]
        state.bookingDetailForm = {
          frequency: PackageFrequencyGroupEnum.AD_HOC,
          postalCode: '',
          addressId: '',
        }
      }

      state.packageDetails = []
      state.addressDetail = null
    },
    setServiceType(
      state,
      { payload }: PayloadAction<{ serviceType: PackageServiceTypeEnum[] }>
    ) {
      state.serviceTypes = payload.serviceType
    },
    // TODO: Create booking context to optimize booking slice for general booking only
    setUpholsteryUnitsQty(
      state,
      {
        payload,
      }: PayloadAction<{
        upholsteryUnitsQty: Record<AvailableClientUpholsteryItem, number>
      }>
    ) {
      const upholsteryUnitsQty = payload.upholsteryUnitsQty
      state.upholsteryUnitsQty = upholsteryUnitsQty

      const upholsteryUnitsDetail = state.upholsteryUnitsDetail
      Object.keys(upholsteryUnitsQty).forEach((unitType) => {
        while (
          upholsteryUnitsDetail[unitType].length < upholsteryUnitsQty[unitType]
        ) {
          let defaultUnitSize: UnitSizeEnum | null = null
          if (
            unitType === PackageUnitTypeEnum.BABY_PRAM ||
            unitType === PackageUnitTypeEnum.CHILD_SEAT
          ) {
            defaultUnitSize = UnitSizeEnum.ALL
          }
          upholsteryUnitsDetail[unitType] = [
            ...upholsteryUnitsDetail[unitType],
            {
              packageDetail: null,
              unitSize: defaultUnitSize,
              serviceType: null,
            },
          ]
        }
      })
    },

    removeUpholsteryUnitsQty(
      state,
      {
        payload,
      }: PayloadAction<{
        unitType: string
        index: number
      }>
    ) {
      state.upholsteryUnitsQty[payload.unitType] -= 1
      state.upholsteryUnitsDetail[payload.unitType].splice(payload.index, 1)
    },
    setUpholsteryUnitsDetail(
      state,
      {
        payload,
      }: PayloadAction<{
        upholsteryUnitsDetail: Record<
          AvailableClientUpholsteryItem,
          UnitDetailType[]
        >
      }>
    ) {
      state.upholsteryUnitsDetail = payload.upholsteryUnitsDetail
    },
    resetUpholsteryUnitsDetail(state) {
      state.upholsteryUnitsDetail = DEFAULT_UPHOLSTERY_UNITS_DETAIL
    },
    setHomeBeautyUnitsDetail(
      state,
      {
        payload,
      }: PayloadAction<{
        homeBeautyUnitsDetail: Record<
          AvailableHomeBeautyService,
          HomeBeautyBookingDetails[]
        >
      }>
    ) {
      state.homeBeautyUnitsDetail = payload.homeBeautyUnitsDetail
    },
    setHomeBeautyServiceOptions(
      state,
      {
        payload: { homeBeautyServiceOptions },
      }: {
        payload: {
          homeBeautyServiceOptions: PackageDetail[]
        }
      }
    ) {
      state.homeBeautyServiceOptions = homeBeautyServiceOptions
    },
    removeHomeBeautyItem(
      state,
      {
        payload,
      }: PayloadAction<{
        serviceType: PackageServiceTypeEnum
        index: number
      }>
    ) {
      state.homeBeautyUnitsDetail[payload.serviceType].splice(payload.index, 1)
    },
    resetHomeBeautyUnitsDetail(state) {
      state.homeBeautyUnitsDetail = DEFAULT_HOME_BEAUTY_UNITS_DETAIL
      state.bookingStepKey = BookingStepEnum.SELECT_BEAUTY_SERVICE
    },
    setBookingDetailForm(
      state,
      {
        payload,
      }: {
        payload: {
          formValues: BookingDetailFormValues
          packageDetails: PackageDetail[]
          addressDetail: Client_client_addresses | null
        }
      }
    ) {
      state.packageDetails = payload.packageDetails
      state.bookingDetailForm = payload.formValues
      state.addressDetail = payload.addressDetail
    },
    setAddressDetail(
      state,
      {
        payload,
      }: {
        payload: {
          addressDetail: Client_client_addresses | null
        }
      }
    ) {
      state.addressDetail = payload.addressDetail
    },
    setServiceInfoForm(
      state,
      {
        payload: { formValues },
      }: { payload: { formValues: ServiceInfoFormValues } }
    ) {
      state.serviceInfoForm = formValues
    },

    setBookingStep(state, { payload }: { payload: BookingStepEnum }) {
      state.bookingStepKey = payload
      if (BookingStepEnum.BOOKING_DETAIL === payload) {
        state.selectedSlot = null
      }
    },

    nextStep(state) {
      state.bookingStepKey = findStepBooking(
        state.department,
        state.bookingStepKey,
        'next'
      )
    },

    prevStep(state) {
      const step = findStepBooking(
        state.department,
        state.bookingStepKey,
        'prev'
      )
      state.bookingStepKey = step
      const stepIndex = BOOKING_ORDER[state.department].findIndex(
        (stepKey) => stepKey === step
      )
      if (stepIndex === 0) {
        state.selectedSlot = null
        state.packageDetails = []
      }
    },

    setSelectedWorkerIds(
      state,
      { payload: { workerIds } }: { payload: { workerIds: string[] | null } }
    ) {
      state.selectedWorkerIds = workerIds
    },

    setModalAddress(state, action: PayloadAction<{ show: boolean }>) {
      state.modalNewAddress = action.payload.show
    },

    setModalContact(
      state,
      action: PayloadAction<{ modalNewContact: boolean }>
    ) {
      state.modalNewContact = action.payload.modalNewContact
    },

    setModalChooseCleaners(
      state,
      action: PayloadAction<{ modalChooseCleaners: boolean }>
    ) {
      state.modalChooseCleaners = action.payload.modalChooseCleaners
    },

    setModalUpsellRecurring(
      state,
      action: PayloadAction<{ modalUpsellRecurring: boolean }>
    ) {
      state.modalUpsellRecurring = action.payload.modalUpsellRecurring
    },

    setModalWorkerPortfolio(
      state,
      action: PayloadAction<{ isWorkerPortfolio: boolean }>
    ) {
      state.isWorkerPortfolio = action.payload.isWorkerPortfolio
    },

    setAvailableSlots(
      state,
      {
        payload: { availableSlots },
      }: { payload: { availableSlots: TimeSlot[] } }
    ) {
      state.availableSlots = availableSlots
    },

    setSelectedSlot(
      state,
      {
        payload: { selectedSlot },
      }: { payload: { selectedSlot: TimeSlot | null } }
    ) {
      state.selectedSlot = selectedSlot

      if (!selectedSlot) {
        return
      }

      if (state.department === PackageDepartmentEnum.HOME_CLEANING) {
        state.packageDetails = state.packageDetails.map((packageDetail) => {
          const sessionSlot =
            selectedSlot.partOfDay === 'Evening'
              ? PackageDetailSessionEnum.EVENING
              : PackageDetailSessionEnum.DAY

          if (packageDetail.session === sessionSlot) {
            packageDetail.serviceBillingValue = selectedSlot.sessionValue!
            packageDetail.unitValue = selectedSlot.rateValue!
          }

          return packageDetail
        })
      }

      // TODO: AIRCON ENHANCEMENT ? will check later
    },

    clearSelectedSlot(state) {
      state.selectedSlot = null
    },

    clearAvailableSlots(state) {
      state.availableSlots = []
    },

    setReservationData(
      state,
      {
        payload: { reservation },
      }: { payload: { reservation: ReservationById_reservationById } }
    ) {
      state.reservation = reservation
    },

    setRescheduledVisitData(
      state,
      {
        payload: { rescheduledVisit },
      }: {
        payload: {
          rescheduledVisit: ClientRescheduleVisitWithToken_clientRescheduleVisitWithToken_visit
        }
      }
    ) {
      state.rescheduledVisit = rescheduledVisit
    },

    clearReservation(state) {
      state.reservation = null
    },
    setReservationExpiredTime(
      state,
      { payload: { expiredTime } }: { payload: { expiredTime: string } }
    ) {
      state.reservationExpiredTime = expiredTime
    },
    clearReservationExpiredTime(state) {
      state.reservationExpiredTime = ''
    },
    cleanupForm(
      state,
      { payload: resetAll = false }: PayloadAction<boolean | undefined>
    ) {
      state.department = PackageDepartmentEnum.HOME_CLEANING
      state.serviceTypes = []
      state.bookingDetailForm = {
        frequency: PackageFrequencyGroupEnum.AD_HOC,
        postalCode: '',
        addressId: '',
      }
      state.serviceInfoForm = {
        contactId: '',
        name: '',
        phoneNumber: '',
        email: '',
        addressId: '',
        postalCode: '',
        accessMode: '',
        accessInstructions: '',
        packageNotes: '',
      }
      state.availableSlots = []
      state.selectedSlot = null
      state.packageDetails = []
      state.addressDetail = null
      state.reservation = null
      state.selectedWorkerIds = null
      state.promo = {
        appliedPromoCode: '',
        computedDiscount: 0,
      }
      if (resetAll) {
        state.bookingStepKey = BookingStepEnum.SELECT_SERVICE
      }
    },

    setAppliedPromo(state, action: PayloadAction<AppliedPromo>) {
      const { appliedPromoCode, computedDiscount } = action.payload

      state.promo = {
        appliedPromoCode,
        computedDiscount,
      }
    },

    setSignUpForBooking(
      state,
      action: PayloadAction<{ signUpForBooking: boolean }>
    ) {
      state.signUpForBooking = action.payload.signUpForBooking
    },
    setSignInForBooking(
      state,
      action: PayloadAction<{ signInForBooking: boolean }>
    ) {
      state.signInForBooking = action.payload.signInForBooking
    },
    setPostBookingDepartment(
      state,
      {
        payload: { postBookingDepartment },
      }: { payload: { postBookingDepartment: PackageDepartmentEnum | null } }
    ) {
      state.postBookingDepartment = postBookingDepartment
    },
  },
})

export const rescheduledVisitSelector = (state: RootState) =>
  state.bookingReducer.rescheduledVisit

export const bookingStepKeySelector = (state: RootState) =>
  state.bookingReducer.bookingStepKey

export const bookingDetailFormValuesSelector = (state: RootState) =>
  state.bookingReducer.bookingDetailForm

export const bookingDepartmentSelector = (state: RootState) =>
  state.bookingReducer.department

export const postBookingDepartmentSelector = (state: RootState) =>
  state.bookingReducer.postBookingDepartment

export const bookingUpholsteryUnitsQtySelector = (state: RootState) =>
  state.bookingReducer.upholsteryUnitsQty

export const bookingUpholsteryUnitsDetailSelector = (state: RootState) =>
  state.bookingReducer.upholsteryUnitsDetail

export const bookingHomeBeautyUnitsDetailSelector = (state: RootState) =>
  state.bookingReducer.homeBeautyUnitsDetail

export const bookingHomeBeautyServiceOptionsSelector = (state: RootState) =>
  state.bookingReducer.homeBeautyServiceOptions

export const screenNeedReservationDataSelector = createSelector(
  bookingStepKeySelector,
  (step) => {
    const bookingStepNeedReservation = [
      BookingStepEnum.SERVICE_INFO,
      BookingStepEnum.CONFIRMATION,
      BookingStepEnum.COMPLETE_BOOKING,
    ]

    return bookingStepNeedReservation.includes(step)
  }
)

export const isInitialBookingStepSelector = createSelector(
  bookingStepKeySelector,
  bookingDepartmentSelector,
  (step, department) => {
    const stepIndex = BOOKING_ORDER[department].findIndex(
      (stepKey) => stepKey === step
    )

    return stepIndex === 0
  }
)

// TODO: Need to find a better name when currently setting booking preference
// like set the service type and detailing the booking
export const isSelectingBookingDetailSelector = (state: RootState) =>
  [BookingStepEnum.SELECT_SERVICE, BookingStepEnum.BOOKING_DETAIL].includes(
    state.bookingReducer.bookingStepKey
  )

export const bookingServiceTypeSelector = (state: RootState) =>
  state.bookingReducer.serviceTypes

export const airconServiceTypeSelector = (
  state: RootState
): [
  AvailableClientAirconService,
  (
    | PackageServiceTypeEnum.JET_WASH
    | PackageServiceTypeEnum.CHEMICAL_WASH
    | undefined
  ),
] => {
  const [serviceType, upgradedServiceType] = state.bookingReducer
    .serviceTypes as [
    AvailableClientAirconService,
    (
      | PackageServiceTypeEnum.JET_WASH
      | PackageServiceTypeEnum.CHEMICAL_WASH
      | undefined
    ),
  ]

  return [serviceType, upgradedServiceType] // TODO: remove later for upgraded service
}

export const bookingFrequencySelector = createSelector(
  (state: RootState) => state.bookingReducer.bookingDetailForm,
  (bookingDetailForm) => bookingDetailForm.frequency
)

export const bookingPackagesSelector = createSelector(
  (state: RootState) => state.bookingReducer.packageDetails,
  (state: RootState) => state.bookingReducer.selectedSlot,
  bookingDepartmentSelector,
  (packageDetails, selectedSlot, department) => {
    if (department === PackageDepartmentEnum.HOME_CLEANING) {
      if (selectedSlot) {
        return packageDetails.filter(({ session }) => {
          const sessionSlot =
            selectedSlot.partOfDay === 'Evening'
              ? PackageDetailSessionEnum.EVENING
              : PackageDetailSessionEnum.DAY

          return session === sessionSlot
        })
      } else {
        return packageDetails.filter(
          ({ session }) => session === PackageDetailSessionEnum.DAY
        )
      }
    }

    return packageDetails
  }
)

export const lineItemIdsFindScheduleVariantSelector = createSelector(
  (state: RootState) => state.bookingReducer.packageDetails,
  bookingDepartmentSelector,
  (packageDetails, department) => {
    let packageDetailIds = packageDetails
      .filter((pkg) => pkg.department === department)
      .map(({ id }) => id)
    if (department === PackageDepartmentEnum.HOME_BEAUTY) {
      packageDetails.forEach((item) => {
        for (let index = 1; index < item.units; index++) {
          packageDetailIds.push(item.id)
        }
      })
    } else if (department === PackageDepartmentEnum.HOME_CLEANING) {
      // only return session DAY on Home Cleaning to find slot
      packageDetailIds = packageDetails
        .filter(
          (pkg) =>
            pkg.department === department &&
            pkg.session === PackageDetailSessionEnum.DAY
        )
        .map(({ id }) => id)
    }

    return packageDetailIds
  }
)

export const workerSkillIdsFindScheduleVariantSelector = createSelector(
  (state: RootState) => state.bookingReducer.packageDetails,
  (packageDetails) => {
    const workerSkillIds = packageDetails.map(
      ({ workerSkill }) => workerSkill.id
    )
    return Array.from(new Set(workerSkillIds)) // remove duplication.
  }
)

export const serviceInfoFormValuesSelector = (state: RootState) =>
  state.bookingReducer.serviceInfoForm

export const selectedWorkerIdsSelector = (state: RootState) =>
  state.bookingReducer.selectedWorkerIds

export const availableSlotsSelector = (state: RootState) =>
  state.bookingReducer.availableSlots

export const groupedAvailableSlotsSelector = createSelector(
  (state: RootState) => state.bookingReducer.availableSlots,
  (availableSlots) => groupTimeSlotByDate(availableSlots)
)

export const selectedSlotSelector = (state: RootState) =>
  state.bookingReducer.selectedSlot

export const reservationSelector = (state: RootState) =>
  state.bookingReducer.reservation

export const reservationExpiredTimeIntervalSelector = createSelector(
  (state: RootState) => state.bookingReducer.reservationExpiredTime,
  (expiredTime) => {
    if (!expiredTime) {
      return 0
    }

    const [date, time] = expiredTime.split(' ')

    const interval = intervalToDuration({
      start: new Date(),
      end: parseDateTime(date, time),
    })

    let intervalSeconds = 0

    if (interval.minutes && interval.seconds) {
      intervalSeconds = interval.minutes * 60 + interval.seconds
    }

    return intervalSeconds
  }
)

export const bookingAddressSelector = (state: RootState) =>
  state.bookingReducer.addressDetail

export const totalPackageDurationSelector = createSelector(
  bookingPackagesSelector,
  (packages) =>
    packages.reduce<number>((total, packageDetail) => {
      if (packageDetail.department == PackageDepartmentEnum.HOME_BEAUTY) {
        return total + packageDetail.duration * packageDetail.units
      } else {
        return total + packageDetail.duration
      }
    }, 0)
)

export const totalServiceBillingValueSelector = createSelector(
  bookingPackagesSelector,
  (packages) =>
    packages.reduce<number>((total, packageDetail) => {
      return total + packageDetail.serviceBillingValue
    }, 0)
)

export const todaysTotalValueSelector = createSelector(
  totalServiceBillingValueSelector,
  bookingDepartmentSelector,
  bookingFrequencySelector,
  (totalServiceBillingValue, department, frequency) => {
    if (
      department === PackageDepartmentEnum.AIRCON &&
      frequency !== PackageFrequencyGroupEnum.AD_HOC
    ) {
      const [, repeatEveryTimes = 1] = getRepeatEveryFrequency(frequency)
      const totalVisits = repeatEveryTimes || 1
      return totalVisits * totalServiceBillingValue
    }

    return totalServiceBillingValue
  }
)

export const airconUnitTypesValueSelector = createSelector(
  bookingPackagesSelector,
  airconServiceTypeSelector,
  (packages, serviceTypes) =>
    serviceTypes.reduce<Record<string, string>>((group, serviceType) => {
      if (serviceType) {
        const unitTypes = SERVICE_TYPE_UNIT_TYPES[serviceType]
        unitTypes.forEach((unitType) => {
          let value = '0'
          const packageDetail = packages.find(
            (pkg) =>
              pkg.serviceType ===
                (serviceType as unknown as PackageServiceTypeEnum) &&
              pkg.unitType === unitType
          )
          if (packageDetail) {
            value = String(packageDetail.units)
          } else if (unitType === PackageUnitTypeEnum.WALL) {
            value = '3'
          }

          group[`${serviceType}_${unitType}`] = value
        })
      }

      return group
    }, {})
)

export const reservationWorkerSelector = (state: RootState) =>
  state.bookingReducer.reservation?.freeTimeslot.worker

export const modalNewAddressSelector = (state: RootState) =>
  state.bookingReducer.modalNewAddress

export const modalNewContactSelector = (state: RootState) =>
  state.bookingReducer.modalNewContact

export const modalChooseCleanersSelector = (state: RootState) =>
  state.bookingReducer.modalChooseCleaners

export const modalUpsellRecurringSelector = (state: RootState) =>
  state.bookingReducer.modalUpsellRecurring

export const workerPortfolioSelector = (state: RootState): boolean =>
  state.bookingReducer.isWorkerPortfolio

export const promoSelector = (state: RootState) => state.bookingReducer.promo

export const signUpForBookingSelector = (state: RootState): boolean =>
  state.bookingReducer.signUpForBooking

export const signInForBookingSelector = (state: RootState): boolean =>
  state.bookingReducer.signInForBooking

export const { name, actions, actions: bookingActions, reducer } = bookingSlice
