import { type LazyQueryResult } from '@apollo/client'
import { useLazyQuery } from '@apollo/react-hooks'

import { type ClientVariables } from '../../../__generated__/Client'
import { type ClientQuery } from '../../../ts/types/Client'
import CLIENT_QUERY from './../__graphql__/Client.graphql'

type QueryResult = LazyQueryResult<ClientQuery, ClientVariables>

const useClientQuery = (): [
  (variables: ClientVariables) => void,
  QueryResult,
] => {
  const [client, result] = useLazyQuery<ClientQuery, ClientVariables>(
    CLIENT_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  )

  function clientQuery(variables: ClientVariables): void {
    client({
      variables,
    })
  }

  return [clientQuery, result]
}

export { useClientQuery }
