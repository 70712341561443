/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CancelVisitReasonEnum {
  CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
  CANCELLED_BY_OPS = 'CANCELLED_BY_OPS',
  CANCELLED_BY_WORKER = 'CANCELLED_BY_WORKER',
  MISSED_BY_WORKER = 'MISSED_BY_WORKER',
  UNABLE_TO_SCHEDULE = 'UNABLE_TO_SCHEDULE',
}

export enum ClientAccountTypeEnum {
  CORPORATE = 'CORPORATE',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum ClientStatusEnum {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
  RESTRICTED = 'RESTRICTED',
}

export enum ContractInvoiceFrequencyEnum {
  PER_MONTH = 'PER_MONTH',
  PER_VISIT = 'PER_VISIT',
}

export enum CreditAccountSortByEnum {
  balance = 'balance',
  created_at = 'created_at',
  credit_type = 'credit_type',
  expiration_date = 'expiration_date',
  id = 'id',
}

export enum CreditAccountStatusEnum {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export enum CreditAccountTransactionStatusEnum {
  AUTHORIZED = 'AUTHORIZED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  VOID = 'VOID',
}

export enum CreditAccountTransactionTypeEnum {
  CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
  CREDIT_ABSORPTION = 'CREDIT_ABSORPTION',
  INITIAL_BALANCE = 'INITIAL_BALANCE',
  PAYMENT = 'PAYMENT',
  REFERRAL = 'REFERRAL',
  REFUND = 'REFUND',
  REWARD = 'REWARD',
  SERVICE_COMPENSATION = 'SERVICE_COMPENSATION',
  TAX_WRITE_OFF = 'TAX_WRITE_OFF',
  TOP_UP = 'TOP_UP',
}

export enum CreditTypeEnum {
  GENERAL = 'GENERAL',
  PACKAGE = 'PACKAGE',
}

export enum DiscountTypeEnum {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

export enum EmailStatusEnum {
  DELIVERED = 'DELIVERED',
  IN_PROCESS = 'IN_PROCESS',
  NOT_DELIVERED = 'NOT_DELIVERED',
  WAITING_TO_BE_SENT = 'WAITING_TO_BE_SENT',
}

export enum IncentiveTypesEnum {
  CREDIT = 'CREDIT',
  PROMO_CODE = 'PROMO_CODE',
  VOUCHER = 'VOUCHER',
}

export enum InvoicePaymentStatusEnum {
  PAID = 'PAID',
  STRIPE_PROCESSING = 'STRIPE_PROCESSING',
  UNDERPAID = 'UNDERPAID',
  UNPAID = 'UNPAID',
}

export enum InvoiceSortByEnum {
  amount = 'amount',
  base_amount = 'base_amount',
  billing_account_client_name = 'billing_account_client_name',
  contract_id = 'contract_id',
  created_at = 'created_at',
  due_date = 'due_date',
  id = 'id',
  issue_date = 'issue_date',
  payment_status = 'payment_status',
  updated_at = 'updated_at',
}

export enum InvoiceStatusEnum {
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  DELETED = 'DELETED',
  NEW = 'NEW',
}

export enum IssueTypeEnum {
  BOOKING_COMMENT = 'BOOKING_COMMENT',
  CLEANER_REPLACEMENT = 'CLEANER_REPLACEMENT',
  GENERAL = 'GENERAL',
  PACKAGE_TERMINATION = 'PACKAGE_TERMINATION',
  PACKAGE_UPDATE = 'PACKAGE_UPDATE',
  REVIEW_SOLICITATION = 'REVIEW_SOLICITATION',
  UNSCHEDULED_VISIT = 'UNSCHEDULED_VISIT',
  VISIT_RESCHEDULE = 'VISIT_RESCHEDULE',
  WARRANTY = 'WARRANTY',
}

export enum JobFrequencyEnum {
  AD_HOC = 'AD_HOC',
  FORTNIGHT = 'FORTNIGHT',
  WEEK = 'WEEK',
}

export enum JobStatusEnum {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export enum PackageDepartmentEnum {
  AIRCON = 'AIRCON',
  BABYSITTER = 'BABYSITTER',
  CARPET_UPHOLSTERY = 'CARPET_UPHOLSTERY',
  CAR_WASH = 'CAR_WASH',
  HANDYMAN = 'HANDYMAN',
  HOME_BEAUTY = 'HOME_BEAUTY',
  HOME_CLEANING = 'HOME_CLEANING',
  MASSAGE = 'MASSAGE',
  OFFICE_CLEANING = 'OFFICE_CLEANING',
}

export enum PackageDetailBillingUnitEnum {
  FIXED = 'FIXED',
  HOURLY = 'HOURLY',
  PER_SQFT = 'PER_SQFT',
}

export enum PackageDetailSessionEnum {
  ALL = 'ALL',
  DAY = 'DAY',
  EVENING = 'EVENING',
}

export enum PackageDetailSortByEnum {
  code = 'code',
  created_at = 'created_at',
  description = 'description',
  duration = 'duration',
  id = 'id',
  updated_at = 'updated_at',
}

export enum PackageRecurrenceEnum {
  AD_HOC = 'AD_HOC',
  DAY = 'DAY',
  FORTNIGHT = 'FORTNIGHT',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export enum PackageRequestStatusEnum {
  CONFIRMED = 'CONFIRMED',
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  PENDING_CLIENT_CONFIRMATION = 'PENDING_CLIENT_CONFIRMATION',
  REJECTED = 'REJECTED',
  SENT_TO_ALL_WORKERS = 'SENT_TO_ALL_WORKERS',
  SENT_TO_PRIORITY_WORKERS = 'SENT_TO_PRIORITY_WORKERS',
}

export enum PackageServiceTypeEnum {
  ADD_ON = 'ADD_ON',
  ADJUSTMENT = 'ADJUSTMENT',
  AD_HOC = 'AD_HOC',
  AIRCON = 'AIRCON',
  BABYSITTING = 'BABYSITTING',
  CARPET_UPHOLSTERY = 'CARPET_UPHOLSTERY',
  CHEMICAL_OVERHAUL = 'CHEMICAL_OVERHAUL',
  CHEMICAL_WASH = 'CHEMICAL_WASH',
  COMBO = 'COMBO',
  CONDENSER_WASH = 'CONDENSER_WASH',
  DEEP_CLEANING = 'DEEP_CLEANING',
  DEEP_EXTRACTION = 'DEEP_EXTRACTION',
  DISCOUNT = 'DISCOUNT',
  DISMANTLING = 'DISMANTLING',
  ELECTRICIAN = 'ELECTRICIAN',
  EQUIPMENT = 'EQUIPMENT',
  FEE = 'FEE',
  GAS_LEAK_TESTING = 'GAS_LEAK_TESTING',
  GAS_TOP_UP = 'GAS_TOP_UP',
  GENERAL_SERVICING = 'GENERAL_SERVICING',
  HARDWARE = 'HARDWARE',
  HOME_CLEANING = 'HOME_CLEANING',
  INSTALLATION = 'INSTALLATION',
  JET_WASH = 'JET_WASH',
  LASHES = 'LASHES',
  MANICURE = 'MANICURE',
  MASSAGE = 'MASSAGE',
  MPV = 'MPV',
  OFFICE_CLEANING = 'OFFICE_CLEANING',
  PEDICURE = 'PEDICURE',
  PLUMBING = 'PLUMBING',
  REPAIR_DIAGNOSTIC = 'REPAIR_DIAGNOSTIC',
  REPAIR_FOLLOW_UP = 'REPAIR_FOLLOW_UP',
  SEDAN = 'SEDAN',
  STEAM_CLEANING = 'STEAM_CLEANING',
  SUV = 'SUV',
  ULTRA_VIOLET_CLEANING = 'ULTRA_VIOLET_CLEANING',
  UPGRADE = 'UPGRADE',
  WARRANTY = 'WARRANTY',
}

export enum PackageStatusEnum {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  TERMINATED = 'TERMINATED',
}

export enum PackageTerminationCategoryEnum {
  PACKAGE_CHANGE = 'PACKAGE_CHANGE',
  TERMINATION = 'TERMINATION',
}

export enum PackageTerminationReasonEnum {
  CLIENT_TERMINATION = 'CLIENT_TERMINATION',
  CLIENT_WILL_SELF_CLEAN = 'CLIENT_WILL_SELF_CLEAN',
  FREQUENT_CANCELLATION = 'FREQUENT_CANCELLATION',
  GOING_OVERSEAS = 'GOING_OVERSEAS',
  HIRED_FULL_TIME_CLEANER = 'HIRED_FULL_TIME_CLEANER',
  MOVING_OUT = 'MOVING_OUT',
  NON_PAYING_CLIENT = 'NON_PAYING_CLIENT',
  OPS_INITIATED = 'OPS_INITIATED',
  OPS_TERMINATED = 'OPS_TERMINATED',
  OTHERS = 'OTHERS',
  PACKAGE_CHANGE = 'PACKAGE_CHANGE',
  POOR_CLEANING_QUALITY = 'POOR_CLEANING_QUALITY',
  PRICING_ISSUE = 'PRICING_ISSUE',
  SERVICE_FRUSTRATIONS = 'SERVICE_FRUSTRATIONS',
  SWITCHED_TO_COMPETITOR = 'SWITCHED_TO_COMPETITOR',
  TEMPORARY_CLEANING_ONLY = 'TEMPORARY_CLEANING_ONLY',
  UNWILLING_TO_SHARE_REASON = 'UNWILLING_TO_SHARE_REASON',
}

export enum PackageUnitTypeEnum {
  ALL = 'ALL',
  BABY_PRAM = 'BABY_PRAM',
  BODY_MASSAGE = 'BODY_MASSAGE',
  CARPET = 'CARPET',
  CEILING = 'CEILING',
  CHILD_SEAT = 'CHILD_SEAT',
  CURTAIN = 'CURTAIN',
  DUCTED = 'DUCTED',
  ELECTRICIAN = 'ELECTRICIAN',
  FOOT_MASSAGE = 'FOOT_MASSAGE',
  HANDYMAN = 'HANDYMAN',
  INSTALLATION = 'INSTALLATION',
  LASHES = 'LASHES',
  MATTRESS = 'MATTRESS',
  MPV = 'MPV',
  NAIL = 'NAIL',
  PLUMBING = 'PLUMBING',
  PORTABLE = 'PORTABLE',
  R22 = 'R22',
  R410A = 'R410A',
  RUG = 'RUG',
  SEDAN = 'SEDAN',
  SOFA = 'SOFA',
  SUV = 'SUV',
  WALL = 'WALL',
  WINDOW = 'WINDOW',
}

export enum PaymentProofStatusEnum {
  NOT_UPLOADED = 'NOT_UPLOADED',
  UPLOADED = 'UPLOADED',
}

export enum PromotionStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ResetPasswordEnum {
  CLAIM_ACCOUNT = 'CLAIM_ACCOUNT',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export enum RewardSortByEnum {
  created_at = 'created_at',
  status = 'status',
  updated_at = 'updated_at',
}

export enum RewardStatusEnum {
  AVAILABLE = 'AVAILABLE',
  CANCELLED = 'CANCELLED',
  EARNED = 'EARNED',
  USED = 'USED',
}

export enum ScheduleJobFrequencyEnum {
  AD_HOC = 'AD_HOC',
  FORTNIGHT = 'FORTNIGHT',
  WEEKLY = 'WEEKLY',
  YEAR = 'YEAR',
}

export enum ScheduleMatchingEnum {
  ALL = 'ALL',
  ANY = 'ANY',
}

export enum ServiceTypeEnum {
  ADD_ON = 'ADD_ON',
  ADJUSTMENT = 'ADJUSTMENT',
  AD_HOC = 'AD_HOC',
  AIRCON = 'AIRCON',
  BABYSITTING = 'BABYSITTING',
  CARPET_UPHOLSTERY = 'CARPET_UPHOLSTERY',
  CHEMICAL_OVERHAUL = 'CHEMICAL_OVERHAUL',
  CHEMICAL_WASH = 'CHEMICAL_WASH',
  COMBO = 'COMBO',
  CONDENSER_WASH = 'CONDENSER_WASH',
  DEEP_CLEANING = 'DEEP_CLEANING',
  DEEP_EXTRACTION = 'DEEP_EXTRACTION',
  DISCOUNT = 'DISCOUNT',
  DISMANTLING = 'DISMANTLING',
  ELECTRICIAN = 'ELECTRICIAN',
  EQUIPMENT = 'EQUIPMENT',
  FEE = 'FEE',
  GAS_LEAK_TESTING = 'GAS_LEAK_TESTING',
  GAS_TOP_UP = 'GAS_TOP_UP',
  GENERAL_SERVICING = 'GENERAL_SERVICING',
  HARDWARE = 'HARDWARE',
  HOME_CLEANING = 'HOME_CLEANING',
  INSTALLATION = 'INSTALLATION',
  JET_WASH = 'JET_WASH',
  LASHES = 'LASHES',
  MANICURE = 'MANICURE',
  MASSAGE = 'MASSAGE',
  MPV = 'MPV',
  OFFICE_CLEANING = 'OFFICE_CLEANING',
  PEDICURE = 'PEDICURE',
  PLUMBING = 'PLUMBING',
  REPAIR_DIAGNOSTIC = 'REPAIR_DIAGNOSTIC',
  REPAIR_FOLLOW_UP = 'REPAIR_FOLLOW_UP',
  SEDAN = 'SEDAN',
  STEAM_CLEANING = 'STEAM_CLEANING',
  SUV = 'SUV',
  ULTRA_VIOLET_CLEANING = 'ULTRA_VIOLET_CLEANING',
  UPGRADE = 'UPGRADE',
  WARRANTY = 'WARRANTY',
}

export enum SignInProviderEnum {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  PASSWORD = 'PASSWORD',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum StripeCardBrandsEnum {
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  DINERS_CLUB = 'DINERS_CLUB',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MASTER_CARD = 'MASTER_CARD',
  UNION_PAY = 'UNION_PAY',
  UNKNOWN = 'UNKNOWN',
  VISA = 'VISA',
}

export enum UnitSizeEnum {
  ALL = 'ALL',
  ARM_CHAIR = 'ARM_CHAIR',
  BABY_COT = 'BABY_COT',
  CHAIR = 'CHAIR',
  C_250SQFT_ABOVE = 'C_250SQFT_ABOVE',
  C_4000SQFT_ABOVE = 'C_4000SQFT_ABOVE',
  C_600SQFT_BELOW = 'C_600SQFT_BELOW',
  C_601SQFT_TO_900SQFT = 'C_601SQFT_TO_900SQFT',
  C_901SQFT_TO_1500SQFT = 'C_901SQFT_TO_1500SQFT',
  EXTRA_LARGE = 'EXTRA_LARGE',
  KING = 'KING',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  QUEEN = 'QUEEN',
  SEATER_1 = 'SEATER_1',
  SEATER_2 = 'SEATER_2',
  SEATER_3 = 'SEATER_3',
  SEATER_4 = 'SEATER_4',
  SEATER_5 = 'SEATER_5',
  SINGLE = 'SINGLE',
  SMALL = 'SMALL',
  SUPER_KING = 'SUPER_KING',
  SUPER_SINGLE = 'SUPER_SINGLE',
}

export enum UserDeviceEnum {
  ANDROID = 'ANDROID',
  BROWSER = 'BROWSER',
  IOS = 'IOS',
  OTHERS = 'OTHERS',
  SYSTEM = 'SYSTEM',
}

export enum UserEnum {
  Client = 'Client',
  Regular = 'Regular',
  Worker = 'Worker',
}

export enum UserPlatformEnum {
  CLIENT_APP = 'CLIENT_APP',
  CLIENT_WEB = 'CLIENT_WEB',
  INTERNAL_WEB = 'INTERNAL_WEB',
  SCRIPT = 'SCRIPT',
  WORKER_APP = 'WORKER_APP',
}

export enum VisitSortByEnum {
  client_billing_value = 'client_billing_value',
  created_at = 'created_at',
  end_time = 'end_time',
  id = 'id',
  service_date = 'service_date',
  start_time = 'start_time',
  status = 'status',
  task_package_address_full_address = 'task_package_address_full_address',
  task_package_contract_client_name = 'task_package_contract_client_name',
  updated_at = 'updated_at',
  worker_first_name = 'worker_first_name',
  worker_last_name = 'worker_last_name',
}

export enum VisitStatusEnum {
  CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
  CANCELLED_BY_OPS = 'CANCELLED_BY_OPS',
  CANCELLED_BY_WORKER = 'CANCELLED_BY_WORKER',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  MISSED_BY_WORKER = 'MISSED_BY_WORKER',
  PENDING_CLIENT_SCHEDULE = 'PENDING_CLIENT_SCHEDULE',
  SCHEDULED = 'SCHEDULED',
  STARTED = 'STARTED',
  UNABLE_TO_SCHEDULE = 'UNABLE_TO_SCHEDULE',
}

export enum VoucherDiscountTypeEnum {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export enum WorkerDepartmentEnum {
  AIRCON = 'AIRCON',
  BABYSITTER = 'BABYSITTER',
  CARPET_UPHOLSTERY = 'CARPET_UPHOLSTERY',
  CAR_WASH = 'CAR_WASH',
  HANDYMAN = 'HANDYMAN',
  HOME_BEAUTY = 'HOME_BEAUTY',
  HOME_CLEANING = 'HOME_CLEANING',
  MASSAGE = 'MASSAGE',
  OFFICE_CLEANING = 'OFFICE_CLEANING',
}

export enum reservationStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
}

export interface AddressInput {
  fullAddress: string
  postalCode: string
  unitNumber?: string | null
  primary?: boolean | null
}

/**
 * Confirmation of actions by user
 */
export interface ConfirmationType {
  key: string
  confirmed?: boolean | null
  message?: string | null
}

export interface ContactInput {
  firstName: string
  lastName: string
  email: string[]
  phoneNumber: string[]
  primary?: boolean | null
}

/**
 * Input for creation source
 */
export interface CreationSourceInput {
  platform?: UserPlatformEnum | null
  device?: UserDeviceEnum | null
}

export interface CreditAccountsFilter {
  creditType?: CreditTypeEnum[] | null
  status?: CreditAccountStatusEnum[] | null
  clientId?: string[] | null
  sortBy?: CreditAccountSortByEnum[] | null
  sort?: SortEnum[] | null
  limit?: number | null
  offset?: number | null
}

/**
 * Input for device-specific data
 */
export interface DeviceInput {
  uid?: string | null
  appVersion?: string | null
  timezone?: string | null
  locale?: string | null
  platform?: string | null
  platformVersion?: string | null
}

/**
 * Input for expo token
 */
export interface ExpoTokenInput {
  token?: string | null
}

/**
 * Input for current installation (= device)
 */
export interface InstallationInput {
  pushNotificationTokens?: PushNotificationTokensInput | null
  device?: DeviceInput | null
}

export interface InvoicesFilter {
  status?: InvoiceStatusEnum[] | null
  paymentStatus?: InvoicePaymentStatusEnum[] | null
  toDate?: any | null
  issueDateFrom?: any | null
  issueDateTo?: any | null
  fromDate?: any | null
  fromDateFrom?: any | null
  fromDateTo?: any | null
  toDateFrom?: any | null
  toDateTo?: any | null
  overdueDaysFrom?: number | null
  overdueDaysTo?: number | null
  paymentProofStatus?: PaymentProofStatusEnum | null
  emailStatus?: EmailStatusEnum | null
  search?: string | null
  clientId?: string[] | null
  managerId?: string[] | null
  sortBy?: InvoiceSortByEnum[] | null
  sort?: SortEnum[] | null
  limit?: number | null
  offset?: number | null
  department?: PackageDepartmentEnum | null
}

/**
 * Input for OneSignal classic token
 */
export interface OneSignalClassicTokenInput {
  playerId: string
}

/**
 * Input for OneSignal VoIP token
 */
export interface OneSignalVoipTokenInput {
  playerId: string
}

export interface PackageDetailsFilter {
  search?: string | null
  department?: PackageDepartmentEnum | null
  repeatEvery?: PackageRecurrenceEnum[] | null
  repeatEveryTimes?: number | null
  duration?: number | null
  session?: PackageDetailSessionEnum | null
  description?: string | null
  limit?: number | null
  offset?: number | null
  sortBy?: PackageDetailSortByEnum[] | null
  sort?: SortEnum[] | null
  unitTypes?: PackageUnitTypeEnum[] | null
  serviceTypes?: ServiceTypeEnum[] | null
}

/**
 * Input for push notification tokens
 */
export interface PushNotificationTokensInput {
  oneSignalClassic?: OneSignalClassicTokenInput | null
  oneSignalVoip?: OneSignalVoipTokenInput | null
  expo?: ExpoTokenInput | null
}

export interface RewardsFilter {
  userId?: string | null
  masterRewardId?: string | null
  incentiveType?: IncentiveTypesEnum | null
  status?: RewardStatusEnum[] | null
  search?: string | null
  sortBy?: RewardSortByEnum[] | null
  sort?: SortEnum[] | null
  limit?: number | null
  offset?: number | null
}

export interface TimeRange {
  day: number
  startTime: string
  endTime: string
}

export interface UtmInput {
  source?: string | null
  medium?: string | null
  campaign?: string | null
  term?: string | null
  content?: string | null
}

export interface VisitReviewsFilter {
  workerId?: string | null
  startDate?: any | null
  endDate?: any | null
  rating?: number | null
  limit?: number | null
  offset?: number | null
  sortBy?: VisitSortByEnum[] | null
  sort?: SortEnum[] | null
}

/**
 * Autogenerated input type of cancelVisitWithToken
 */
export interface cancelVisitWithTokenInput {
  clientMutationId?: string | null
  token: string
  reason?: CancelVisitReasonEnum | null
  confirmations?: ConfirmationType[] | null
}

/**
 * Autogenerated input type of clientApplyCredit
 */
export interface clientApplyCreditInput {
  clientMutationId?: string | null
  invoiceId: string
  amount: number
}

/**
 * Autogenerated input type of cancelVisitWithToken
 */
export interface cancelVisitWithTokenInput {
  clientMutationId?: string | null
  token: string
  reason?: CancelVisitReasonEnum | null
  confirmations?: ConfirmationType[] | null
}

/**
 * Autogenerated input type of clientApplyVoucher
 */
export interface clientApplyVoucherInput {
  clientMutationId?: string | null
  invoiceId: string
  voucherId: string
}

/**
 * Autogenerated input type of clientCancelReservation
 */
export interface clientCancelReservationInput {
  clientMutationId?: string | null
  reservationIds: string[]
}

/**
 * Autogenerated input type of clientCancelVisit
 */
export interface clientCancelVisitInput {
  clientMutationId?: string | null
  visitId: string
  reason?: CancelVisitReasonEnum | null
  confirmations?: ConfirmationType[] | null
}

/**
 * Autogenerated input type of clientConfirmPhoneNumber
 */
export interface clientConfirmPhoneNumberInput {
  clientMutationId?: string | null
  phone: string
  code: string
  onSignUp?: boolean | null
}

/**
 * Autogenerated input type of clientConfirmReservation
 */
export interface clientConfirmReservationInput {
  clientMutationId?: string | null
  accessType?: string | null
  additionalNotes?: string | null
  fullAddress: string
  postalCode: string
  unitNumber?: string | null
  contractId?: string | null
  reservationId: string
  endDate?: any | null
  salespersonShortcode?: string | null
  workerChosenByClient?: boolean | null
  promoCode?: string | null
  creationSource?: CreationSourceInput | null
}

/**
 * Autogenerated input type of clientCreateReservation
 */
export interface clientCreateReservationInput {
  clientMutationId?: string | null
  slotId: string
  serviceDate: any
  startTime: string
  endTime: string
  frequencyOfJob: ScheduleJobFrequencyEnum
  lineItemIds?: string[] | null
}

/**
 * Autogenerated input type of clientRemoveVoucher
 */
export interface clientRemoveVoucherInput {
  clientMutationId?: string | null
  invoiceId: string
}

/**
 * Autogenerated input type of clientRescheduleVisit
 */
export interface clientRescheduleVisitInput {
  clientMutationId?: string | null
  id: string
  serviceDate?: any | null
  startTime?: string | null
  endTime?: string | null
  workerId?: string | null
}

/**
 * Autogenerated input type of clientRescheduleVisitWithToken
 */
export interface clientRescheduleVisitWithTokenInput {
  clientMutationId?: string | null
  token: string
  serviceDate: any
  startTime: string
  endTime: string
  statusComment?: string | null
  workerId: string
}

/**
 * Autogenerated input type of clientValidatePromoCode
 */
export interface clientValidatePromoCodeInput {
  clientMutationId?: string | null
  promoCode: string
  lineItemIds: string[]
  clientId?: string | null
}

/**
 * Autogenerated input type of clientVerifyPhoneNumber
 */
export interface clientVerifyPhoneNumberInput {
  clientMutationId?: string | null
  phone: string
  inAndroidFormat?: boolean | null
  onSignUp?: boolean | null
  enableWhatsappOtp?: boolean | null
}

/**
 * Autogenerated input type of createAddress
 */
export interface createAddressInput {
  clientMutationId?: string | null
  clientId: string
  fullAddress: string
  unitNumber?: string | null
  postalCode: string
  primary: boolean
}

/**
 * Autogenerated input type of createBookingRequest
 */
export interface createBookingRequestInput {
  clientMutationId?: string | null
  name?: string | null
  email?: string | null
  phoneNumber?: string | null
  postalCode: string
  clientId?: string | null
  jobFrequency: JobFrequencyEnum
  sessionPerWeek: number
  durationPerSession: number
  comment?: string | null
}

/**
 * Autogenerated input type of createClient
 */
export interface createClientInput {
  clientMutationId?: string | null
  name: string
  accountType: ClientAccountTypeEnum
  primaryAddress: AddressInput
  otherAddresses?: AddressInput[] | null
  primaryContact: ContactInput
  otherContacts?: ContactInput[] | null
}

/**
 * Autogenerated input type of createClientIssueRequest
 */
export interface createClientIssueRequestInput {
  clientMutationId?: string | null
  visitId: string
  issueId: string
  comment: string
}

/**
 * Autogenerated input type of createClientIssueRequestWithToken
 */
export interface createClientIssueRequestWithTokenInput {
  clientMutationId?: string | null
  token: string
  issueId: string
  comment: string
}

/**
 * Autogenerated input type of createClientIssueRequestWithToken
 */
export interface createClientIssueRequestWithTokenInput {
  clientMutationId?: string | null
  token: string
  issueId: string
  comment: string
}

/**
 * Autogenerated input type of createContact
 */
export interface createContactInput {
  clientMutationId?: string | null
  clientId: string
  firstName: string
  lastName: string
  email: string[]
  phoneNumber: string[]
  primary: boolean
}

/**
 * Autogenerated input type of createPackageIssueRequest
 */
export interface createPackageIssueRequestInput {
  clientMutationId?: string | null
  packageId: string
  issueId: string
  comment: string
}

/**
 * Autogenerated input type of createPaymentMethod
 */
export interface createPaymentMethodInput {
  clientMutationId?: string | null
  clientId: string
  token: string
}

/**
 * Autogenerated input type of deleteAddress
 */
export interface deleteAddressInput {
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of deleteClient
 */
export interface deleteClientInput {
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of deleteContact
 */
export interface deleteContactInput {
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of deletePaymentMethod
 */
export interface deletePaymentMethodInput {
  clientMutationId?: string | null
  clientId: string
  paymentMethodId: string
}

/**
 * Autogenerated input type of generateInvoicePdf
 */
export interface generateInvoicePdfInput {
  clientMutationId?: string | null
  token: string
}

/**
 * Autogenerated input type of generateInvoiceReceiptPdf
 */
export interface generateInvoiceReceiptPdfInput {
  clientMutationId?: string | null
  token: string
}

/**
 * Autogenerated input type of payInvoice
 */
export interface payInvoiceInput {
  clientMutationId?: string | null
  invoiceId: string
}

/**
 * Autogenerated input type of rateVisit
 */
export interface rateVisitInput {
  clientMutationId?: string | null
  visitId: string
  rating: number
  ratingComment?: string | null
  files?: any[] | null
}

/**
 * Autogenerated input type of rateVisitWithToken
 */
export interface rateVisitWithTokenInput {
  clientMutationId?: string | null
  token: string
  rating: number
  ratingComment?: string | null
}

/**
 * Autogenerated input type of rescheduleVisitWithToken
 */
export interface rescheduleVisitWithTokenInput {
  clientMutationId?: string | null
  token: string
  serviceDate?: any | null
  startTime?: string | null
  endTime?: string | null
  workerId?: string | null
}

/**
 * Autogenerated input type of rescheduleVisitWithToken
 */
export interface rescheduleVisitWithTokenInput {
  clientMutationId?: string | null
  token: string
  serviceDate?: any | null
  startTime?: string | null
  endTime?: string | null
  workerId?: string | null
}

/**
 * Autogenerated input type of resetPasswordFromEmail
 */
export interface resetPasswordFromEmailInput {
  clientMutationId?: string | null
  email: string
  pinNumber: string
  newPassword: string
}

/**
 * Autogenerated input type of resetPasswordFromPhone
 */
export interface resetPasswordFromPhoneInput {
  clientMutationId?: string | null
  phone: string
  pinNumber: string
  newPassword: string
}

/**
 * Autogenerated input type of sendPinCodeToPhone
 */
export interface sendPinCodeToPhoneInput {
  clientMutationId?: string | null
  phone: string
  enableWhatsappOtp?: boolean | null
  inAndroidFormat?: boolean | null
}

/**
 * Autogenerated input type of sendReferralInvitation
 */
export interface sendReferralInvitationInput {
  clientMutationId?: string | null
  email: string
}

/**
 * Autogenerated input type of sendResetPassword
 */
export interface sendResetPasswordInput {
  clientMutationId?: string | null
  email: string
  type?: ResetPasswordEnum | null
}

/**
 * Autogenerated input type of signInClient
 */
export interface signInClientInput {
  clientMutationId?: string | null
  email: string
  password: string
  type?: UserEnum | null
  installation?: InstallationInput | null
}

/**
 * Autogenerated input type of signInClientSocialAccount
 */
export interface signInClientSocialAccountInput {
  clientMutationId?: string | null
  token: string
  refreshToken: string
  provider: SignInProviderEnum
  installation?: InstallationInput | null
}

/**
 * Autogenerated input type of signInWithPhone
 */
export interface signInWithPhoneInput {
  clientMutationId?: string | null
  phone: string
  code: string
  installation?: InstallationInput | null
}

/**
 * Autogenerated input type of signInWithPhone
 */
export interface signInWithPhoneInput {
  clientMutationId?: string | null
  phone: string
  code: string
  installation?: InstallationInput | null
}

/**
 * Autogenerated input type of signUp
 */
export interface signUpInput {
  clientMutationId?: string | null
  email: string
  phoneNumber: string
  password?: string | null
  provider?: SignInProviderEnum | null
  token?: string | null
  refreshToken?: string | null
  firstName: string
  lastName: string
  type?: UserEnum | null
  accountType?: ClientAccountTypeEnum | null
  primaryAddress?: AddressInput | null
  primaryContact?: ContactInput | null
  installation?: InstallationInput | null
  utm?: UtmInput | null
  adTrackingKey?: string | null
  inviteCode?: string | null
  creationSource?: CreationSourceInput | null
}

/**
 * Autogenerated input type of terminatePackage
 */
export interface terminatePackageInput {
  clientMutationId?: string | null
  packageId: string
  endDate: any
  terminationCategory: PackageTerminationCategoryEnum
  terminationReason: PackageTerminationReasonEnum
  terminationComment?: string | null
}

/**
 * Autogenerated input type of tokenizePaymentMethod
 */
export interface tokenizePaymentMethodInput {
  clientMutationId?: string | null
  name: string
  expMonth: string
  expYear: string
  number: string
  cvc: string
}

/**
 * Autogenerated input type of updateAccountPhoneNumber
 */
export interface updateAccountPhoneNumberInput {
  clientMutationId?: string | null
  clientId: string
  phoneNumber: string
}

/**
 * Autogenerated input type of updateAddress
 */
export interface updateAddressInput {
  clientMutationId?: string | null
  id: string
  fullAddress?: string | null
  postalCode?: string | null
  primary?: boolean | null
  unitNumber?: string | null
}

/**
 * Autogenerated input type of updateClient
 */
export interface updateClientInput {
  clientMutationId?: string | null
  id: string
  name?: string | null
  accountType?: ClientAccountTypeEnum | null
  accountEmail?: string | null
  accountPhoneNumber?: string | null
  status?: ClientStatusEnum | null
}

/**
 * Autogenerated input type of updateClientPreferences
 */
export interface updateClientPreferencesInput {
  clientMutationId?: string | null
  preferences: any
  clientId: string
}

/**
 * Autogenerated input type of updateContact
 */
export interface updateContactInput {
  clientMutationId?: string | null
  id: string
  firstName?: string | null
  lastName?: string | null
  email?: string[] | null
  phoneNumber?: string[] | null
  primary?: boolean | null
}

/**
 * Autogenerated input type of updatePaymentIndicated
 */
export interface updatePaymentIndicatedInput {
  clientMutationId?: string | null
  invoiceId: string
}

/**
 * Autogenerated input type of updatePaymentMethod
 */
export interface updatePaymentMethodInput {
  clientMutationId?: string | null
  clientId: string
  paymentMethodId: string
  cvc?: string | null
  expMonth?: string | null
  expYear?: string | null
  name?: string | null
}

/**
 * Autogenerated input type of verifyUser
 */
export interface verifyUserInput {
  clientMutationId?: string | null
  code: string
  email: string
}

//==============================================================
// END Enums and Input Objects
//==============================================================
