import {
  type Client_client_addresses,
  type Client_client_contacts,
} from '__generated__/Client'
import {
  JobFrequencyEnum,
  PackageDepartmentEnum,
  PackageDetailSessionEnum,
  PackageRecurrenceEnum,
  PackageServiceTypeEnum,
  PackageUnitTypeEnum,
  ScheduleJobFrequencyEnum,
  UnitSizeEnum,
  WorkerDepartmentEnum,
  PackageDetailBillingUnitEnum,
} from '__generated__/globalTypes'
import { type SelectOption } from '@luce/ui-kit'
import addHours from 'date-fns/addHours'
import { type AvailableEvent, triggerEvent } from 'services/gtm'
import { shortDayByIndex } from 'ts/types/Days'
import { type PackageDetail } from 'ts/types/Package'
import { formatDate, timeFormat } from 'utils/date'
import { formatWorkerFirstLastName } from 'utils/helpers'

import { type ClientCancelVisit_clientCancelVisit_visit } from './../../__generated__/ClientCancelVisit'
import { type ClientConfirmReservation_clientConfirmReservation } from './../../__generated__/ClientConfirmReservation'
import { type ClientFindBookingSchedule_clientFindBookingSchedule } from './../../__generated__/ClientFindBookingSchedule'
import { type ClientFindScheduleVariants_clientFindScheduleVariants } from './../../__generated__/ClientFindScheduleVariants'
import { type BookingDetailFormValues, type TimeSlot } from './booking.slice'
import { type BookingType } from './BookingPage.container'
import {
  getWorkerPortfolios,
  homeBeautyDetailMapping,
} from './formWizard/homeBeautyComponents/homeBeautyHelper'

export type WorkerCardData = {
  id: string
  workerName: string
  avatarUrl: string
}

export enum PackageFrequencyGroupEnum {
  AD_HOC = 'AD_HOC',
  FORTNIGHT = 'FORTNIGHT',
  WEEKLY = 'WEEKLY',
  TRI_YEARLY = 'TRI_YEARLY',
  QUARTERLY = 'QUARTERLY',
  WEEKLY_PLUS = 'WEEKLY_PLUS',
}

export type RecurrencePackage =
  | 'Ad Hoc'
  | 'Weekly'
  | 'Fortnightly'
  | 'Daily'
  | 'Monthly'
  | 'Yearly'
  | 'Quarterly'
  | 'Tri-Yearly'
  | 'Weekly Plus'

export type FormStatus = { success?: boolean; error?: string }

export type CustomTime = { startTime: string | null; endTime: string | null }

export const initialCustomTime = (): CustomTime[] => {
  const defaultData: CustomTime[] = [
    { startTime: null, endTime: null },
    { startTime: null, endTime: null },
    { startTime: null, endTime: null },
    { startTime: null, endTime: null },
    { startTime: null, endTime: null },
    { startTime: null, endTime: null },
    { startTime: null, endTime: null },
  ]

  return defaultData
}
export interface AdHocFormValues {
  clientId: string
  name: string
  phoneNumber: string
  email: string
  postalCode: string
  jobFrequency: ScheduleJobFrequencyEnum
  sessionPerWeek: string
  durationPerSession: string
  comment: string
}

export function getBookingFrequency(
  scheduleFrequency: ScheduleJobFrequencyEnum
): JobFrequencyEnum {
  switch (scheduleFrequency) {
    case ScheduleJobFrequencyEnum.WEEKLY:
      return JobFrequencyEnum.WEEK
    case ScheduleJobFrequencyEnum.FORTNIGHT:
      return JobFrequencyEnum.FORTNIGHT
    default:
      return JobFrequencyEnum.AD_HOC
  }
}

export function mapClientAddress(
  addresses: Client_client_addresses[]
): { value: string; label: string }[] {
  return addresses.map((address) => ({
    value: address.id,
    label: `${address.fullAddress}, ${address.postalCode}, Singapore`,
  }))
}

export function mapClientContact(
  contacts: Client_client_contacts[]
): { value: string; label: string }[] {
  return contacts.map((contact) => ({
    value: contact.id,
    label: formatWorkerFirstLastName(contact.firstName, contact.lastName),
  }))
}

export function getAddressDetail(
  addressId: string,
  addresses: Client_client_addresses[]
): Client_client_addresses {
  return addresses.find(({ id }) => id === addressId)!
}

export function getContactDetail(
  contactId: string,
  contacts: Client_client_contacts[]
): Client_client_contacts {
  return contacts.find(({ id }) => id === contactId)!
}

export const PackageFrequencyGroupLabel: Record<
  PackageFrequencyGroupEnum,
  RecurrencePackage
> = {
  [PackageFrequencyGroupEnum.WEEKLY]: 'Weekly',
  [PackageFrequencyGroupEnum.FORTNIGHT]: 'Fortnightly',
  [PackageFrequencyGroupEnum.AD_HOC]: 'Ad Hoc',
  [PackageFrequencyGroupEnum.QUARTERLY]: 'Quarterly',
  [PackageFrequencyGroupEnum.TRI_YEARLY]: 'Tri-Yearly',
  [PackageFrequencyGroupEnum.WEEKLY_PLUS]: 'Weekly Plus',
}

export const PackageUnitTypeMapping: Record<PackageUnitTypeEnum, string> = {
  [PackageUnitTypeEnum.ALL]: 'All',
  [PackageUnitTypeEnum.R22]: 'R22',
  [PackageUnitTypeEnum.R410A]: 'R401',
  [PackageUnitTypeEnum.DUCTED]: 'Ducted',
  [PackageUnitTypeEnum.CEILING]: 'Casette (Ceiling)',
  [PackageUnitTypeEnum.PORTABLE]: 'Portable',
  [PackageUnitTypeEnum.WALL]: 'Wall-Mounted',
  [PackageUnitTypeEnum.WINDOW]: 'Window',
  [PackageUnitTypeEnum.CARPET]: 'Carpet',
  [PackageUnitTypeEnum.MATTRESS]: 'Mattress',
  [PackageUnitTypeEnum.RUG]: 'Rug',
  [PackageUnitTypeEnum.SOFA]: 'Sofa',
  [PackageUnitTypeEnum.NAIL]: 'Nail art',
  [PackageUnitTypeEnum.BABY_PRAM]: 'Baby Pram',
  [PackageUnitTypeEnum.CHILD_SEAT]: 'Child Seat',
  [PackageUnitTypeEnum.CURTAIN]: 'Curtain',
  [PackageUnitTypeEnum.PLUMBING]: 'Plumbing',
  [PackageUnitTypeEnum.MPV]: 'Mpv',
  [PackageUnitTypeEnum.SUV]: 'Suv',
  [PackageUnitTypeEnum.SEDAN]: 'Sedan',
  [PackageUnitTypeEnum.BODY_MASSAGE]: 'Body Massage',
  [PackageUnitTypeEnum.ELECTRICIAN]: 'Electrician',
  [PackageUnitTypeEnum.FOOT_MASSAGE]: 'Foot Massage',
  [PackageUnitTypeEnum.HANDYMAN]: 'Handyman',
  [PackageUnitTypeEnum.INSTALLATION]: 'Installation',
  [PackageUnitTypeEnum.LASHES]: 'Eye Lashes',
}

export type SizeOptions = {
  mattressSize: SelectOption[]
  rugSize: SelectOption[]
  sofaSize: SelectOption[]
  babyPramSize: SelectOption[]
  chidSeatSize: SelectOption[]
}

export const PackageUnitSizeMapping: Record<UnitSizeEnum, string> = {
  [UnitSizeEnum.ALL]: 'All',
  [UnitSizeEnum.BABY_COT]: 'Baby Cot',
  [UnitSizeEnum.KING]: 'King (182cm x 190cm)',
  [UnitSizeEnum.SUPER_KING]: 'Super King (182cm x 200cm)',
  [UnitSizeEnum.QUEEN]: 'Queen (152cm x 190cm) ',
  [UnitSizeEnum.SINGLE]: 'Single (91cm x 190cm)',
  [UnitSizeEnum.SUPER_SINGLE]: 'Super Single (107cm x 190cm)',
  [UnitSizeEnum.SMALL]: 'Small (up to 100x100cm)',
  [UnitSizeEnum.MEDIUM]: 'Medium (up to 100x200cm)',
  [UnitSizeEnum.LARGE]: 'Large (up to 200x200cm)',
  [UnitSizeEnum.EXTRA_LARGE]: 'Extra Large (300x300cm or larger)',
  [UnitSizeEnum.ARM_CHAIR]: 'Arm Chair',
  [UnitSizeEnum.CHAIR]: 'Chair',
  [UnitSizeEnum.SEATER_1]: 'Seater 1',
  [UnitSizeEnum.SEATER_2]: 'Seater 2',
  [UnitSizeEnum.SEATER_3]: 'Seater 3',
  [UnitSizeEnum.SEATER_4]: 'Seater 4',
  [UnitSizeEnum.SEATER_5]: 'Seater 5',
  [UnitSizeEnum.C_600SQFT_BELOW]: '600 Sqft or Below',
  [UnitSizeEnum.C_601SQFT_TO_900SQFT]: '601 Sqft to 900 Sqft',
  [UnitSizeEnum.C_901SQFT_TO_1500SQFT]: '901 Sqft to 1500 Sqft',
  [UnitSizeEnum.C_4000SQFT_ABOVE]: '4000 Sqft above',
  [UnitSizeEnum.C_250SQFT_ABOVE]: '250 Sqft above',
}

export const getUpholsterySizeUnit = (
  packageDetails: PackageDetail[]
): SizeOptions => {
  const sizeItem: SizeOptions = {
    mattressSize: [],
    rugSize: [],
    sofaSize: [],
    babyPramSize: [],
    chidSeatSize: [],
  }

  const sortingPackageDetails = packageDetails
    .slice()
    .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))

  sortingPackageDetails.forEach((packageDetail) => {
    const newItem: SelectOption = {
      label: PackageUnitSizeMapping[packageDetail.unitSize],
      value: packageDetail.unitSize,
    }

    if (
      packageDetail.unitType === PackageUnitTypeEnum.MATTRESS &&
      packageDetail.serviceType === PackageServiceTypeEnum.DEEP_EXTRACTION
    ) {
      sizeItem.mattressSize.push(newItem)
    } else if (packageDetail.unitType === PackageUnitTypeEnum.RUG) {
      sizeItem.rugSize.push(newItem)
    } else if (packageDetail.unitType === PackageUnitTypeEnum.SOFA) {
      sizeItem.sofaSize.push(newItem)
    } else if (packageDetail.unitType === PackageUnitTypeEnum.BABY_PRAM) {
      sizeItem.babyPramSize.push(newItem)
    } else if (packageDetail.unitType === PackageUnitTypeEnum.CHILD_SEAT) {
      sizeItem.chidSeatSize.push(newItem)
    }
  })

  return sizeItem
}

export const PackageRecurrenceMapping: Record<
  PackageRecurrenceEnum,
  RecurrencePackage
> = {
  [PackageRecurrenceEnum.AD_HOC]: 'Ad Hoc',
  [PackageRecurrenceEnum.DAY]: 'Daily',
  [PackageRecurrenceEnum.FORTNIGHT]: 'Fortnightly',
  [PackageRecurrenceEnum.MONTH]: 'Monthly',
  [PackageRecurrenceEnum.WEEK]: 'Weekly',
  [PackageRecurrenceEnum.YEAR]: 'Yearly',
}

export const getPackageServiceTypeLabel = (
  serviceType: PackageServiceTypeEnum
): string => {
  switch (serviceType) {
    case PackageServiceTypeEnum.AD_HOC:
      return 'AdHoc' // TODO: This is unnecessary
    case PackageServiceTypeEnum.AIRCON:
      return 'Aircon' // TODO: This is unnecessary
    case PackageServiceTypeEnum.CHEMICAL_OVERHAUL:
      return 'Chemical Overhaul'
    case PackageServiceTypeEnum.CHEMICAL_WASH:
      return 'Chemical Wash'
    case PackageServiceTypeEnum.DISMANTLING:
      return 'Dismantling'
    case PackageServiceTypeEnum.EQUIPMENT:
      return 'Equipment'
    case PackageServiceTypeEnum.GAS_LEAK_TESTING:
      return 'Gas Leak Testing'
    case PackageServiceTypeEnum.GAS_TOP_UP:
      return 'Gas Top Up'
    case PackageServiceTypeEnum.GENERAL_SERVICING:
      return 'General Servicing'
    case PackageServiceTypeEnum.HOME_CLEANING:
      return 'Home Cleaning'
    case PackageServiceTypeEnum.INSTALLATION:
      return 'Installation'
    case PackageServiceTypeEnum.JET_WASH:
      return 'Jet Wash'
    case PackageServiceTypeEnum.OFFICE_CLEANING:
      return 'Office Cleaning'
    case PackageServiceTypeEnum.REPAIR_DIAGNOSTIC:
      return 'Repair Diagnostic'
    case PackageServiceTypeEnum.REPAIR_FOLLOW_UP:
      return 'Repair Follow Up'
    case PackageServiceTypeEnum.CONDENSER_WASH:
      return 'Condenser Wash'
    case PackageServiceTypeEnum.DEEP_EXTRACTION:
      return 'Deep Extraction'
    case PackageServiceTypeEnum.ULTRA_VIOLET_CLEANING:
      return 'UV-C Cleaning'
    case PackageServiceTypeEnum.ADJUSTMENT:
      return 'Adjustment'
    case PackageServiceTypeEnum.WARRANTY:
      return 'Warranty' // TODO: This only used internal app
    case PackageServiceTypeEnum.COMBO:
      return 'Manicure Pedicure'
    case PackageServiceTypeEnum.MANICURE:
      return 'Manicure'
    case PackageServiceTypeEnum.PEDICURE:
      return 'Pedicure'
    case PackageServiceTypeEnum.ADD_ON:
      return 'Add on'
    case PackageServiceTypeEnum.UPGRADE:
      return 'Upgrade'
    case PackageServiceTypeEnum.DISCOUNT:
      return 'Discount'
    case PackageServiceTypeEnum.DEEP_CLEANING:
      return 'Deep Cleaning'
    case PackageServiceTypeEnum.CARPET_UPHOLSTERY:
      return 'Carpet Upholstery'
    case PackageServiceTypeEnum.HARDWARE:
      return 'Hardware'
    case PackageServiceTypeEnum.STEAM_CLEANING:
      return 'Steam Cleaning'
    case PackageServiceTypeEnum.BABYSITTING:
      return 'Babysitting'
    case PackageServiceTypeEnum.ELECTRICIAN:
      return 'Electrician'
    case PackageServiceTypeEnum.MASSAGE:
      return 'Message'
    case PackageServiceTypeEnum.LASHES:
      return 'Eye Lashes'
    case PackageServiceTypeEnum.PLUMBING:
      return 'Plumbing'
    case PackageServiceTypeEnum.SEDAN:
      return 'Sedan'
    case PackageServiceTypeEnum.MPV:
      return 'Mpv'
    case PackageServiceTypeEnum.SUV:
      return 'Suv'
    case PackageServiceTypeEnum.FEE:
      return 'Fee'

    default:
      console.log(
        'unknown service type on getPackageServiceTypeLabel',
        serviceType
      )
      return 'Unknown Service Type'
  }
}

export const PackageDetailSessionMapping: Record<
  PackageDetailSessionEnum,
  string
> = {
  [PackageDetailSessionEnum.ALL]: 'All',
  [PackageDetailSessionEnum.DAY]: 'Day',
  [PackageDetailSessionEnum.EVENING]: 'Evening',
}

export const PackageDetailBillingUnitMapping: Record<
  PackageDetailBillingUnitEnum,
  string
> = {
  [PackageDetailBillingUnitEnum.FIXED]: 'Units',
  [PackageDetailBillingUnitEnum.HOURLY]: 'Hours',
  [PackageDetailBillingUnitEnum.PER_SQFT]: 'Per sqft',
}

function getPackageBillingUnitLabel(
  billingUnit: PackageDetailBillingUnitEnum,
  department: PackageDepartmentEnum
): string {
  if (
    department === PackageDepartmentEnum.OFFICE_CLEANING &&
    billingUnit === PackageDetailBillingUnitEnum.FIXED
  ) {
    return 'Hours'
  }

  return PackageDetailBillingUnitMapping[billingUnit]
}

export type LineItemDetail = {
  packageDetailId: string
  packageCode: string
  serviceTitle: string
  serviceLabel: string
  unitsLabel: string
  unitsNumber: number
  unitValue: number
  billingValue: number
  serviceType: PackageServiceTypeEnum
  department: PackageDepartmentEnum
}

const HCRepeatEvery = {
  0: '',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
}

function serviceLabelForAirconOrOther(packageDetail: PackageDetail) {
  const serviceTitle = `${getPackageServiceTypeLabel(
    packageDetail.serviceType
  )} (${getFrequencyLabel(
    packageDetail.repeatEvery,
    packageDetail.repeatEveryTimes
  )})`

  const serviceLabel = PackageUnitTypeMapping[packageDetail.unitType]

  const servicePackageCode = packageDetail.code
  return {
    serviceTitle,
    serviceLabel,
    servicePackageCode,
  }
}

function serviceLabelForHomeOrOffice(packageDetail: PackageDetail) {
  const serviceTitle = `${getFrequencyLabel(
    packageDetail.repeatEvery,
    packageDetail.repeatEveryTimes
  )} ${getPackageServiceTypeLabel(packageDetail.serviceType)} (${
    PackageDetailSessionMapping[packageDetail.session]
  })`

  const serviceLabel = `${HCRepeatEvery[packageDetail.repeatEveryTimes]} ${
    packageDetail.duration
  }-Hour Session`

  return {
    serviceTitle,
    serviceLabel,
  }
}

export function getLineItemClientDetail(
  packageDetail: PackageDetail,
  options?: {
    unitValue?: number
    billingValue?: number
    unitNumber?: number
  }
): LineItemDetail {
  const cacheKey = `${packageDetail.id}-${packageDetail.serviceType}-${
    options?.unitValue ?? packageDetail.unitValue
  }-${options?.billingValue ?? packageDetail.serviceBillingValue}-${
    options?.unitNumber ?? packageDetail.units
  }`

  if (getLineItemClientDetail.cache[cacheKey]) {
    return getLineItemClientDetail.cache[cacheKey]
  }

  const isHomeOrOfficeClean = [
    PackageDepartmentEnum.HOME_CLEANING,
    PackageDepartmentEnum.OFFICE_CLEANING,
  ].includes(packageDetail.department)

  const isHomeBeautyService =
    PackageDepartmentEnum.HOME_BEAUTY === packageDetail.department

  let serviceTitle = ''
  let serviceLabel = ''

  if (isHomeOrOfficeClean) {
    const label = serviceLabelForHomeOrOffice(packageDetail)
    serviceTitle = label.serviceTitle
    serviceLabel = label.serviceLabel
  } else if (isHomeBeautyService) {
    const label = serviceLabelForAirconOrOther(packageDetail)
    serviceTitle =
      label.serviceTitle === 'Discount (Ad Hoc)'
        ? 'Free Item'
        : label.serviceTitle
    serviceLabel = homeBeautyDetailMapping(label.servicePackageCode ?? '').title
  } else {
    const label = serviceLabelForAirconOrOther(packageDetail)
    serviceTitle = label.serviceTitle
    serviceLabel = label.serviceLabel
  }

  getLineItemClientDetail.cache[cacheKey] = {
    packageDetailId: packageDetail.id,
    serviceTitle,
    serviceLabel,
    department: packageDetail.department,
    packageCode: packageDetail.code,
    unitsLabel: getPackageBillingUnitLabel(
      packageDetail.billingUnit,
      packageDetail.department
    ),
    unitsNumber: packageDetail.units,
    unitValue: options?.unitValue ?? packageDetail.unitValue,
    billingValue: options?.billingValue ?? packageDetail.serviceBillingValue,
    serviceType: packageDetail.serviceType,
  }

  return getLineItemClientDetail.cache[cacheKey]
}
getLineItemClientDetail.cache = {} as Record<string, LineItemDetail>

export const accessMode = {
  KEYPAD_PIN: 'Keypad PIN',
  RING_DOORBELL: 'Ring Doorbell',
}

export function accessModeOptions() {
  return Object.entries(accessMode).map(([value, label]) => ({
    label,
    value,
  }))
}

export const partOfDaySessionMapping: Record<
  'Morning' | 'Evening' | 'Afternoon',
  PackageDetailSessionEnum
> = {
  Morning: PackageDetailSessionEnum.DAY,
  Evening: PackageDetailSessionEnum.EVENING,
  Afternoon: PackageDetailSessionEnum.EVENING,
}

export function transformScheduleBookingToTimeSlot(
  scheduleVariants: ClientFindBookingSchedule_clientFindBookingSchedule[]
): TimeSlot[] {
  const currentNext5Hr = addHours(new Date(), 5)

  return scheduleVariants.reduce<TimeSlot[]>((allTimeSlot, scheduleVariant) => {
    scheduleVariant.morning &&
      scheduleVariant.morning.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        if (currentNext5Hr < startDate) {
          // Only show slot with minimum next 5hr
          allTimeSlot.push({
            id: `${scheduleVariant.day}-morning-${i}`,
            slotId: item.id,
            date: formatDate(startDate),
            day: shortDayByIndex(scheduleVariant.day),
            partOfDay: 'Morning',
            startTime: formatDate(startDate, timeFormat),
            endTime: formatDate(endDate, timeFormat),
            averageTravelTime: item.averageTravelTime,
            workerId: item.worker.id,
            workerName: `${item.worker.firstName} ${item.worker.lastName}`,
            rateValue: item.rateValue ?? 0,
            sessionValue: item.sessionValue ?? 0,
            avatarUrl: item.worker.avatarUrl ?? '',
            workerRating: item.worker.workerRating,
            portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),
          })
        }
      })

    scheduleVariant.afternoon &&
      scheduleVariant.afternoon.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        if (currentNext5Hr < startDate) {
          // Only show slot with minimum next 5hr
          allTimeSlot.push({
            id: `${scheduleVariant.day}-afternoon-${i}`,
            slotId: item.id,
            date: formatDate(startDate),
            day: shortDayByIndex(scheduleVariant.day),
            partOfDay: 'Afternoon',
            startTime: formatDate(startDate, timeFormat),
            endTime: formatDate(endDate, timeFormat),
            averageTravelTime: item.averageTravelTime,
            workerId: item.worker.id,
            workerName: `${item.worker.firstName} ${item.worker.lastName}`,
            rateValue: item.rateValue ?? 0,
            sessionValue: item.sessionValue ?? 0,
            avatarUrl: item.worker.avatarUrl ?? '',
            workerRating: item.worker.workerRating,
            portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),
          })
        }
      })

    scheduleVariant.evening &&
      scheduleVariant.evening.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        if (currentNext5Hr < startDate) {
          // Only show slot with minimum next 5hr
          allTimeSlot.push({
            id: `${scheduleVariant.day}-evening-${i}`,
            slotId: item.id,
            date: formatDate(startDate),
            day: shortDayByIndex(scheduleVariant.day),
            partOfDay: 'Evening',
            startTime: formatDate(startDate, timeFormat),
            endTime: formatDate(endDate, timeFormat),
            averageTravelTime: item.averageTravelTime,
            workerId: item.worker.id,
            workerName: `${item.worker.firstName} ${item.worker.lastName}`,
            rateValue: item.rateValue ?? 0,
            sessionValue: item.sessionValue ?? 0,
            avatarUrl: item.worker.avatarUrl ?? '',
            workerRating: item.worker.workerRating,
            portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),
          })
        }
      })

    return allTimeSlot
  }, [])
}

export function transformScheduleVariantToTimeSlot(
  scheduleVariants: ClientFindScheduleVariants_clientFindScheduleVariants[]
): TimeSlot[] {
  return scheduleVariants.reduce<TimeSlot[]>((allTimeSlot, scheduleVariant) => {
    scheduleVariant.morning &&
      scheduleVariant.morning.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        allTimeSlot.push({
          id: `${scheduleVariant.day}-morning-${i}`,
          slotId: item.id,
          date: formatDate(startDate),
          day: shortDayByIndex(scheduleVariant.day),
          partOfDay: 'Morning',
          startTime: formatDate(startDate, timeFormat),
          endTime: formatDate(endDate, timeFormat),
          averageTravelTime: item.averageTravelTime,
          workerId: item.worker.id,
          avatarUrl: item.worker.avatarUrl ?? '',
          workerRating: item.worker.workerRating,
          workerName: `${item.worker.firstName} ${item.worker.lastName}`,
          portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),
        })
      })

    scheduleVariant.afternoon &&
      scheduleVariant.afternoon.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        allTimeSlot.push({
          id: `${scheduleVariant.day}-afternoon-${i}`,
          slotId: item.id,
          date: formatDate(startDate),
          day: shortDayByIndex(scheduleVariant.day),
          partOfDay: 'Afternoon',
          startTime: formatDate(startDate, timeFormat),
          endTime: formatDate(endDate, timeFormat),
          averageTravelTime: item.averageTravelTime,
          workerId: item.worker.id,
          avatarUrl: item.worker.avatarUrl ?? '',
          workerRating: item.worker.workerRating,
          workerName: `${item.worker.firstName} ${item.worker.lastName}`,
          portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),
        })
      })

    scheduleVariant.evening &&
      scheduleVariant.evening.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        allTimeSlot.push({
          id: `${scheduleVariant.day}-evening-${i}`,
          slotId: item.id,
          date: formatDate(startDate),
          day: shortDayByIndex(scheduleVariant.day),
          partOfDay: 'Evening',
          startTime: formatDate(startDate, timeFormat),
          endTime: formatDate(endDate, timeFormat),
          averageTravelTime: item.averageTravelTime,
          workerId: item.worker.id,
          avatarUrl: item.worker.avatarUrl ?? '',
          workerRating: item.worker.workerRating,
          workerName: `${item.worker.firstName} ${item.worker.lastName}`,
          portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),
        })
      })

    return allTimeSlot
  }, [])
}

export function groupTimeSlotByDate(
  timeSlots: TimeSlot[]
): Record<string, TimeSlot[]> {
  return timeSlots.reduce<Record<string, TimeSlot[]>>((group, timeslot) => {
    if (group[timeslot.date]) {
      group[timeslot.date].push(timeslot)
    } else {
      group[timeslot.date] = [timeslot]
    }

    return group
  }, {})
}

export function getFrequencyLabel(
  repeatEvery: PackageRecurrenceEnum,
  repeatEveryTimes: number
): RecurrencePackage {
  switch (repeatEvery) {
    case PackageRecurrenceEnum.WEEK:
      return 'Weekly'
    case PackageRecurrenceEnum.FORTNIGHT:
      return 'Fortnightly'
    case PackageRecurrenceEnum.MONTH:
      return 'Monthly'
    case PackageRecurrenceEnum.YEAR:
      if (repeatEveryTimes === 3) {
        return 'Tri-Yearly'
      } else if (repeatEveryTimes === 4) {
        return 'Quarterly'
      } else {
        return 'Yearly'
      }
    default:
      return 'Ad Hoc'
  }
}

export function getRepeatEveryFrequency(
  frequency: PackageFrequencyGroupEnum
): [PackageRecurrenceEnum[] | undefined, number | undefined] {
  switch (frequency) {
    case PackageFrequencyGroupEnum.AD_HOC:
      return [[PackageRecurrenceEnum.AD_HOC], 0]
    case PackageFrequencyGroupEnum.WEEKLY:
      return [[PackageRecurrenceEnum.WEEK], 1]
    case PackageFrequencyGroupEnum.FORTNIGHT:
      return [[PackageRecurrenceEnum.FORTNIGHT], 1]
    case PackageFrequencyGroupEnum.TRI_YEARLY:
      return [[PackageRecurrenceEnum.YEAR], 3]
    case PackageFrequencyGroupEnum.QUARTERLY:
      return [[PackageRecurrenceEnum.YEAR], 4]

    default:
      return [undefined, undefined]
  }
}

export function getFrequencyOfjob(
  frequency: PackageFrequencyGroupEnum
): ScheduleJobFrequencyEnum {
  switch (frequency) {
    case PackageFrequencyGroupEnum.AD_HOC:
      return ScheduleJobFrequencyEnum.AD_HOC
    case PackageFrequencyGroupEnum.FORTNIGHT:
      return ScheduleJobFrequencyEnum.FORTNIGHT
    case PackageFrequencyGroupEnum.WEEKLY:
      return ScheduleJobFrequencyEnum.WEEKLY
    case PackageFrequencyGroupEnum.TRI_YEARLY:
    case PackageFrequencyGroupEnum.QUARTERLY:
      return ScheduleJobFrequencyEnum.YEAR
    default:
      return ScheduleJobFrequencyEnum.AD_HOC
  }
}

export function getPromoCode(packageDescription: string): string {
  return packageDescription.replace('Promo code:', '').trim()
}

export const CS_NUMBER = '+65 3138 4827'

export function cleanupNumber(number: string): string {
  return number.replace(/\D/g, '')
}

export const getCSWALink = (): string => {
  return `https://wa.me/${cleanupNumber(CS_NUMBER)}`
}

export const workerLabel = (
  department: PackageDepartmentEnum | WorkerDepartmentEnum
): string => {
  switch (department) {
    case PackageDepartmentEnum.CARPET_UPHOLSTERY:
      return 'technician'
    case PackageDepartmentEnum.AIRCON:
      return 'technician'
    case WorkerDepartmentEnum.AIRCON:
      return 'technician'
    case PackageDepartmentEnum.HOME_CLEANING:
      return 'cleaner'
    case WorkerDepartmentEnum.HOME_BEAUTY:
      return 'beauty technician'
    case PackageDepartmentEnum.OFFICE_CLEANING:
    case WorkerDepartmentEnum.OFFICE_CLEANING:
      return 'cleaner'

    default:
      return 'cleaner'
  }
}
function selectCardHomeCleaningMapping(
  frequencyGroup: PackageFrequencyGroupEnum
): AvailableEvent {
  switch (frequencyGroup) {
    case PackageFrequencyGroupEnum.AD_HOC:
      return 'client_web_select_hc_adhoc'
    case PackageFrequencyGroupEnum.FORTNIGHT:
      return 'client_web_select_hc_fortnightly'
    case PackageFrequencyGroupEnum.WEEKLY:
      return 'client_web_select_hc_weekly'
    default:
      return 'client_web_select_hc_weeklyplus'
  }
}

export const sendSelectCardHomeCleanAnalyticData = (
  selectedPackage: PackageFrequencyGroupEnum,
  userId: string
) => {
  const selectedCard = selectCardHomeCleaningMapping(selectedPackage)
  triggerEvent(selectedCard, { user: { id: userId } })
}

const selectEventMapping: { [key in BookingType]: AvailableEvent } = {
  home: 'client_web_select_hc',
  aircon: 'client_web_select_ac',
  upholstery: 'client_web_select_uc',
  beauty: 'client_web_select_hb',
}

export const sendSelectServiceAnalyticData = (
  serviceDepartment: BookingType,
  userId: string
) => {
  const selectEvent = selectEventMapping[serviceDepartment]
  triggerEvent(selectEvent, { user: { id: userId } })
}

type GAPurchaseFromReservationData = {
  transaction_id: string
  value: number
  currency: 'SGD'
  items: {
    item_id: string
    item_name: string
    currency: 'SGD'
    item_brand: 'Luce'
    item_category: 'HOME_CLEANING' | 'AIRCON'
    price: number
    quantity: number
  }[]
}

export function transformReservationDataToGAPurchase(
  frequency: PackageFrequencyGroupEnum,
  reservation: ClientConfirmReservation_clientConfirmReservation
): GAPurchaseFromReservationData {
  let multiplier: number
  switch (frequency) {
    case PackageFrequencyGroupEnum.AD_HOC:
    case PackageFrequencyGroupEnum.QUARTERLY:
    case PackageFrequencyGroupEnum.TRI_YEARLY:
      multiplier = 1
      break
    case PackageFrequencyGroupEnum.FORTNIGHT:
      multiplier = 2
      break
    case PackageFrequencyGroupEnum.WEEKLY:
      multiplier = 4
      break
    default:
      multiplier = 0
      break
  }

  return {
    transaction_id: reservation.package.id,
    value: reservation.package.serviceBillingValue * multiplier,
    currency: 'SGD',
    items: Array(multiplier).fill({
      item_id: reservation.package.packageDetail.id,
      item_name: reservation.package.packageDetail.code,
      currency: 'SGD',
      item_brand: 'Luce',
      item_category: reservation.package.packageDetail.department as
        | 'AIRCON'
        | 'HOME_CLEANING',
      price: reservation.package.unitValue,
      quantity: reservation.package.packageDetail.duration,
    }),
  }
}

const purchaseEventMapping: {
  [key in PackageDepartmentEnum]: AvailableEvent
} = {
  [PackageDepartmentEnum.HOME_CLEANING]: 'client_web_purchase_hc',
  [PackageDepartmentEnum.AIRCON]: 'client_web_purchase_ac',
  [PackageDepartmentEnum.OFFICE_CLEANING]: 'client_web_purchase_oc',
  [PackageDepartmentEnum.CARPET_UPHOLSTERY]: 'client_web_purchase_uc',
  [PackageDepartmentEnum.HOME_BEAUTY]: 'client_web_purchase_hb',
  [PackageDepartmentEnum.BABYSITTER]: 'client_web_booking_babysitter',
  [PackageDepartmentEnum.CAR_WASH]: 'client_web_booking_car_wash',
  [PackageDepartmentEnum.HANDYMAN]: 'client_web_booking_handyman',
  [PackageDepartmentEnum.MASSAGE]: 'client_web_booking_message',
}

export const sendPurchaseAnalyticData = (
  serviceDepartment: PackageDepartmentEnum,
  frequency: PackageFrequencyGroupEnum,
  reservation: ClientConfirmReservation_clientConfirmReservation,
  userId: string
) => {
  const purchaseEvent = purchaseEventMapping[serviceDepartment]

  triggerEvent(purchaseEvent, {
    ecommerce: transformReservationDataToGAPurchase(frequency, reservation),
    user: { id: userId },
  })
}

type GAAddToCartData = {
  currency: 'SGD'
  value: number
  items: {
    item_id: string
    item_name: string
    currency: 'SGD'
    item_brand: 'Luce'
    item_category: 'HOME_CLEANING' | 'AIRCON'
    item_variant: PackageFrequencyGroupEnum | null
    price: number
    quantity: number
    postalCode: string
  }[]
}

// TODO: need to think again the calculation on aircon booking and when have a promo
export function transformBookingDataToGAAddToCart(
  bookingDetailForm: BookingDetailFormValues,
  packageDetails: PackageDetail[]
): GAAddToCartData {
  const totalServiceBillingValue = packageDetails.reduce<number>(
    (total, { serviceBillingValue }) => total + serviceBillingValue,
    0
  )

  return {
    currency: 'SGD',
    value: totalServiceBillingValue,
    items: packageDetails.map((packageDetail) => ({
      item_id: packageDetail.id,
      item_name: packageDetail.code,
      currency: 'SGD',
      item_brand: 'Luce',
      item_category: packageDetail.department as 'AIRCON' | 'HOME_CLEANING',
      item_variant: bookingDetailForm.frequency,
      price: packageDetail.unitValue,
      quantity: packageDetail.duration,
      postalCode: bookingDetailForm.postalCode,
    })),
  }
}

const addToCartEventMapping: {
  [key in PackageDepartmentEnum]: AvailableEvent
} = {
  [PackageDepartmentEnum.HOME_CLEANING]: 'client_web_add_to_cart_hc',
  [PackageDepartmentEnum.AIRCON]: 'client_web_add_to_cart_ac',
  [PackageDepartmentEnum.OFFICE_CLEANING]: 'client_web_add_to_cart_oc',
  [PackageDepartmentEnum.CARPET_UPHOLSTERY]: 'client_web_add_to_cart_uc',
  [PackageDepartmentEnum.HOME_BEAUTY]: 'client_web_add_to_cart_hb',
  [PackageDepartmentEnum.BABYSITTER]: 'client_web_add_to_cart_babysitter',
  [PackageDepartmentEnum.CAR_WASH]: 'client_web_add_to_cart_car_wash',
  [PackageDepartmentEnum.HANDYMAN]: 'client_web_add_to_cart_handyman',
  [PackageDepartmentEnum.MASSAGE]: 'client_web_add_to_cart_message',
}

export const sendAddToCartAnalyticData = (
  serviceDepartment: PackageDepartmentEnum,
  bookingDetailFormValues: BookingDetailFormValues,
  packageDetails: PackageDetail[],
  userId: string
) => {
  const addToCartEvent = addToCartEventMapping[serviceDepartment]

  triggerEvent(addToCartEvent, {
    ecommerce: transformBookingDataToGAAddToCart(
      bookingDetailFormValues,
      packageDetails
    ),
    user: { id: userId },
  })
}

type GARefundDataFromVisit = {
  currency: 'SGD'
  transaction_id: string
  value: number
  items: {
    item_id: string
    item_name: string
    currency: 'SGD'
    price: number
    quantity: number
  }[]
}

export function transformVisitDataTOGARefund(
  cancelVisit: ClientCancelVisit_clientCancelVisit_visit
): GARefundDataFromVisit {
  const packageData = cancelVisit.task.package

  return {
    currency: 'SGD',
    transaction_id: packageData.id,
    value: packageData.unitValue * packageData.packageDetail.duration,
    items: [
      {
        item_id: packageData.packageDetail.id,
        item_name: packageData.packageDetail.code,
        currency: 'SGD',
        price: packageData.unitValue,
        quantity: packageData.packageDetail.duration,
      },
    ],
  }
}

type DiscountValue = {
  percent: number
  total: number
}

const DISCOUNT_UPHOLSTERY_TWO_UNITS = 5
const DISCOUNT_UPHOLSTERY_THREE_UNITS = 10
const DISCOUNT_UPHOLSTERY_MORE_THAN_THREE_UNITS = 15

export function calculateServiceDiscount(
  totalUnit: number,
  totalBillingValue: number,
  department: PackageDepartmentEnum
): DiscountValue {
  let percentDiscount = 0
  let totalDiscount = 0

  if (department === PackageDepartmentEnum.CARPET_UPHOLSTERY) {
    if (totalUnit > 3) {
      percentDiscount = DISCOUNT_UPHOLSTERY_MORE_THAN_THREE_UNITS
    } else if (totalUnit === 3) {
      percentDiscount = DISCOUNT_UPHOLSTERY_THREE_UNITS
    } else if (totalUnit === 2) {
      percentDiscount = DISCOUNT_UPHOLSTERY_TWO_UNITS
    }

    totalDiscount = (percentDiscount * totalBillingValue) / 100
  }

  return {
    percent: percentDiscount,
    total: totalDiscount,
  }
}

export function getWorkerDepartment(
  department: PackageDepartmentEnum
): WorkerDepartmentEnum {
  switch (department) {
    case PackageDepartmentEnum.HOME_CLEANING:
      return WorkerDepartmentEnum.HOME_CLEANING
    case PackageDepartmentEnum.OFFICE_CLEANING:
      return WorkerDepartmentEnum.OFFICE_CLEANING
    case PackageDepartmentEnum.HOME_BEAUTY:
      return WorkerDepartmentEnum.HOME_BEAUTY
    default:
      return WorkerDepartmentEnum.AIRCON
  }
}
