const getCookie = (key: string): string | null => {
  key = key + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')

  for (let cookie of cookies) {
    while (cookie.startsWith(' ')) {
      cookie = cookie.substring(1)
    }

    if (cookie.startsWith(key)) {
      return cookie.substring(key.length, cookie.length)
    }
  }

  return null
}

const setCookie = (
  key: string,
  value: string,
  expInDays = 7,
  path = '/'
): string => {
  const now = new Date()
  now.setTime(now.getTime() + expInDays * 24 * 3600 * 1000)

  document.cookie = [
    `${key}=${value}`,
    `expires=${now.toUTCString()}`,
    `path=${path}`,
  ].join(';')

  return value
}

export { getCookie, setCookie }
