import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from '../../redux/slices'

type State = {
  showVoucherModal: boolean
  showRemoveVoucherConfirmation: boolean
  invoiceId: string
  referralCode: string | undefined
}

export const initialState: State = {
  showVoucherModal: false,
  showRemoveVoucherConfirmation: false,
  invoiceId: '',
  referralCode: '',
}

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    setShowModal(state, action: PayloadAction<boolean>) {
      state.showVoucherModal = action.payload
    },
    setInvoiceId(state, action: PayloadAction<string>) {
      state.invoiceId = action.payload
    },
    setShowRemoveConfirmation(state, action: PayloadAction<boolean>) {
      state.showRemoveVoucherConfirmation = action.payload
    },
    setReferralCode(
      state,
      {
        payload: { referralCode },
      }: { payload: { referralCode: string | undefined } }
    ) {
      state.referralCode = referralCode
    },
  },
})

export const invoiceIdSelector = (state: RootState): string =>
  state.voucherReducer.invoiceId

export const showVoucherModalSelector = (state: RootState): boolean =>
  state.voucherReducer.showVoucherModal

export const showRemoveVoucherConfirmationSelector = (
  state: RootState
): boolean => state.voucherReducer.showRemoveVoucherConfirmation

export const referralCodeSelector = (state: RootState) =>
  state.voucherReducer.referralCode

export const { name, actions, reducer } = voucherSlice
