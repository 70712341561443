import Box from '@luce/ui-kit/components/base/Box'
import Typography from '@luce/ui-kit/components/base/Typography'
// import Button from '@luce/ui-kit/components/button/Button'
import Icon from '@luce/ui-kit/components/icons/Icons'
import LuceAppBar from '@luce/ui-kit/components/molecules/AppBar'
import LuceTheme from '@luce/ui-kit/themes/default'
import { makeStyles, type Theme } from '@luce/ui-kit/themes/ThemeProvider'
import AvatarPreview from 'components/user/Avatar'
import useAuth from 'hooks/AuthHook'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import { getAvatarLetter } from 'utils/helpers'

// import { actions } from '../../redux/slices/modalSlice'
import {
  tabletScreen,
  useMediaQuery,
} from '../booking/ui-component/MediaQueryResponsive'
import {
  actions,
  showLogOutModalSelector,
} from './../../components/profile/profile.slice'
import NavigationDrawer from './NavigationDrawer'
// import MenuItemList, { MenuItemData } from './ui-component/MenuItemList'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1201,
    height: 64,
    // position: 'relative',
    '& .MuiToolbar-gutters': {
      padding: 0,
    },
  },
  placeholder: {
    flexGrow: 1,
  },
  linkStyle: {
    textDecoration: 'none',
    color: theme.palette.common.white,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    ...theme.typography.body1,
    '&:hover': {
      background: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    '&> a': {
      color: theme.palette.common.white,
    },
  },
  accountProfileLink: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  activeLink: {
    background: theme.palette.secondary.main,
  },
  avatar: {
    ...LuceTheme.typography.overline,
    color: LuceTheme.palette.primary.main,
    background: LuceTheme.palette.grey[400],
    textDecoration: 'none',
    '&:hover': {
      background: LuceTheme.palette.grey[600],
    },
  },
  hideDisplay: {
    display: 'none',
  },
  unsetDisplay: {
    display: 'unset',
  },
}))

interface Props {}

// TODO: Need to enhance the logic to dynamicly show menu item and app bar
const routeHiddenMenuItems = ['login', 'reset-password', 'rate', 'invoice-pdf']

const AppBar: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const { user, hasCreditAccount, logout } = useAuth()
  const location = useLocation()
  const history = useHistory()
  const tabScreen = useMediaQuery(tabletScreen)
  const classes = useStyles()
  const locationRoute = location.pathname.slice(1)

  const showLogOutModal = useSelector(showLogOutModalSelector)

  const onLogout = () => {
    logout()
    history.replace('/')
  }

  const handleLogout = () => {
    dispatch(actions.setshowLogOutModal(true))
  }

  const handleCloseLogout = () => {
    dispatch(actions.setshowLogOutModal(false))
  }

  const showActiveLink = (item: string): string | undefined => {
    if (locationRoute.includes(item)) {
      return classes.activeLink
    }
  }

  return (
    <LuceAppBar
      className={`${classes.root} ${
        locationRoute === 'login' && classes.hideDisplay
      }`}
      position="sticky"
    >
      <Box pl={3} display="flex" alignItems="center">
        {!routeHiddenMenuItems.includes(locationRoute) && tabScreen && user && (
          <NavigationDrawer
            onLogout={onLogout}
            showLogOutModal={showLogOutModal}
            handleLogout={handleLogout}
            handleCloseLogout={handleCloseLogout}
            activePageRoute={locationRoute}
          />
        )}
        <RouterLink to={user ? '/home' : '/booking'}>
          <img width={66} src="/logo.svg" alt="luce-logo" color="ffffff" />
        </RouterLink>
        {!routeHiddenMenuItems.includes(locationRoute) && user && (
          <Box
            className={tabScreen ? classes.hideDisplay : ''}
            ml={7}
            display="flex"
            alignItems="center"
          >
            <RouterLink
              className={`${classes.linkStyle} ${showActiveLink('booking')}`}
              to="/booking"
            >
              <Box display="flex" alignItems="center" gridGap={6}>
                <Icon iconName="AllServicesIcon" />
                <Typography variant="body1"> Booking</Typography>
              </Box>
            </RouterLink>
          </Box>
        )}
      </Box>
      <Box className={classes.placeholder} />
      {!routeHiddenMenuItems.includes(locationRoute) && user && (
        <Box>
          <Box display="flex" alignItems="center">
            {tabScreen ? null : (
              <>
                <RouterLink
                  className={`${classes.linkStyle} ${showActiveLink(
                    'upcoming'
                  )}`}
                  to="/upcoming"
                >
                  <Typography variant="body1">Upcoming</Typography>
                </RouterLink>
                <RouterLink
                  className={`${classes.linkStyle} ${showActiveLink(
                    'history'
                  )}`}
                  to="/history"
                >
                  <Typography variant="body1">History</Typography>
                </RouterLink>

                <RouterLink
                  className={`${classes.linkStyle} ${showActiveLink(
                    'rewards'
                  )}`}
                  to="/rewards"
                >
                  <Typography variant="body1">Rewards</Typography>
                </RouterLink>

                <RouterLink
                  className={`${classes.linkStyle} ${showActiveLink(
                    'invoice'
                  )}`}
                  to="/invoice"
                >
                  <Typography variant="body1">
                    {hasCreditAccount ? 'Credits & Invoices' : 'Invoices'}
                  </Typography>
                </RouterLink>
              </>
            )}
            <RouterLink
              className={`
              ${classes.linkStyle}
              ${classes.accountProfileLink}
              ${showActiveLink('profile')}
              `}
              to="/profile"
            >
              <Box display="flex" alignItems="center" gridGap={16}>
                <Typography variant="body2">{user.firstName}</Typography>
                <AvatarPreview
                  img={user.avatarUrl}
                  customSize="medium"
                  avatarLetter={getAvatarLetter(user)}
                  className={classes.avatar}
                />
              </Box>
            </RouterLink>
          </Box>
        </Box>
      )}
    </LuceAppBar>
  )
}

export default AppBar
