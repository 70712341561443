import addDays from 'date-fns/addDays'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import intervalToDuration from 'date-fns/intervalToDuration'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import startOfDay from 'date-fns/startOfDay'

export const dateFormat = 'yyyy-MM-dd'
export const timeFormat = 'HH:mm:ss'

export const ShortMonthMapping = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

export const ShortDayMapping = {
  0: 'Mon',
  1: 'Tue',
  2: 'Wed',
  3: 'Thu',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun',
}

export function parseDate(
  dateString: string,
  format: string = dateFormat,
  referenceDate: Date | number = new Date()
): Date {
  if (parseDate.cache[dateString]) {
    return parseDate.cache[dateString]
  }

  parseDate.cache[dateString] = parse(dateString, format, referenceDate)
  return parseDate.cache[dateString]
}

parseDate.cache = {} as Record<string, Date>
parseDate.date = new Date()

export function formatDefaultDate(date: string): string {
  if (!date) {
    return 'No Date'
  }
  const setDateValue = date.split('-')
  const dayInt = parseInt(setDateValue[2])
  const monthInt = parseInt(setDateValue[1])
  const yearInt = parseInt(setDateValue[0])
  const leadingZeroDay = dayInt < 10 ? `0${dayInt}` : dayInt
  const leadingZeroMonth = monthInt < 10 ? `0${monthInt}` : monthInt

  return `${leadingZeroDay}/${leadingZeroMonth}/${yearInt}`
}

export function formatDateName(date: string): string {
  const getDate = new Date(date)
  const formattedDate = format(getDate, 'EEE, dd MMM yyyy')

  return formattedDate
}
export function formatDate(
  date: Date | number,
  template: string = dateFormat
): string {
  const cacheName = `${date.valueOf()}-${template}`
  if (formatDate.cache[cacheName]) {
    return formatDate.cache[cacheName]
  }

  formatDate.cache[cacheName] = format(date, template)
  return formatDate.cache[cacheName]
}
formatDate.cache = {} as Record<string, string>

export function parseDateTime(
  dateString,
  timeString,
  referenceDate: Date | number = new Date()
): Date {
  const dateTimeString = `${dateString}-${timeString}`
  if (parseDateTime.cache[dateTimeString]) {
    return parseDateTime.cache[dateTimeString]
  }
  parseDateTime.cache[dateTimeString] = parse(
    dateTimeString,
    'yyyy-MM-dd-HH:mm:ss',
    referenceDate
  )
  return parseDateTime.cache[dateTimeString]
}
parseDateTime.cache = {} as Record<string, Date>

export function formatDateTime(
  date: Date | number,
  template = 'yyyy-MM-dd HH:mm:ss'
): string {
  return format(date, template)
}

export function addTime(timeString: string, date: Date): Date {
  const [hours, minutes] = timeString.split(':').map((str) => parseInt(str))
  let newDate = addHours(date, hours)
  newDate = addMinutes(newDate, minutes)
  return newDate
}

export function splitTimeInt(timeString: string): {
  hour: number
  minute: number
  second: number
} {
  const [hour, minute, second] = timeString.split(':').map((v) => Number(v))
  return {
    hour,
    minute,
    second,
  }
}

type FormatTimeOption = {
  separator?: string
  ignoreZero?: boolean
  capitalize?: 'lowercase' | 'uppercase'
}
export function formatTimeAMPM(
  timeString: string,
  options: FormatTimeOption = {
    separator: ':',
    ignoreZero: false,
    capitalize: 'uppercase',
  }
): string {
  const { separator, ignoreZero, capitalize } = options
  const keyname = `${timeString}-${JSON.stringify(
    options
  )}-${ignoreZero}-${capitalize}`

  if (!timeString) {
    return 'No Time'
  }

  if (formatTimeAMPM.cache[keyname]) {
    return formatTimeAMPM.cache[keyname]
  }

  const hours =
    splitTimeInt(timeString).hour < 10
      ? `${ignoreZero ? '' : '0'}${splitTimeInt(timeString).hour}`
      : splitTimeInt(timeString).hour
  const minutes =
    splitTimeInt(timeString).minute < 10
      ? `${ignoreZero ? '' : '0'}${splitTimeInt(timeString).minute}`
      : splitTimeInt(timeString).minute
  const ampm = splitTimeInt(timeString).hour >= 12 ? 'PM' : 'AM'

  formatTimeAMPM.cache[keyname] = `${hours}${separator}${minutes} ${
    capitalize === 'lowercase' ? ampm.toLocaleLowerCase() : ampm
  }`

  return formatTimeAMPM.cache[keyname]
}
formatTimeAMPM.cache = {} as Record<string, string>

export function getMinuteFromTimeInterval(
  startTime: string,
  endTime: string
): number {
  const keyname = `${startTime}-${endTime}`

  if (getMinuteFromTimeInterval.cache[keyname]) {
    return getMinuteFromTimeInterval.cache[keyname]
  }

  const startDate = new Date(`1970-01-01T${startTime}`)
  const endDate = new Date(`1970-01-01T${endTime}`)
  const hour = differenceInMinutes(endDate, startDate)

  getMinuteFromTimeInterval.cache[keyname] = hour
  return getMinuteFromTimeInterval.cache[keyname]
}
getMinuteFromTimeInterval.cache = {} as Record<string, number>

export function getHourFromDateTimeInterval(
  startDateTime: string,
  endDateTime: string
): number {
  const keyname = `${startDateTime}-${endDateTime}`

  if (getHourFromDateTimeInterval.cache[keyname]) {
    return getHourFromDateTimeInterval.cache[keyname]
  }

  const startDate = new Date(startDateTime)
  const endDate = new Date(endDateTime)
  const hour = differenceInHours(endDate, startDate)

  getHourFromDateTimeInterval.cache[keyname] = hour
  return getHourFromDateTimeInterval.cache[keyname]
}
getHourFromDateTimeInterval.cache = {} as Record<string, number>

export function getDateRangeBetween(startDate: Date, endDate: Date): Date[] {
  const dates: Date[] = []
  let currentDate = startOfDay(startDate)
  while (currentDate <= endOfDay(endDate)) {
    dates.push(currentDate)
    currentDate = addDays(currentDate, 1)
  }
  return dates
}

export function formatShortMonth(date: string): string {
  if (!date) {
    return 'No Date'
  }

  const setDateValue = date.split('-')
  const dateInt = parseInt(setDateValue[2])
  const monthInt = parseInt(setDateValue[1])
  const yearInt = parseInt(setDateValue[0])

  return `${dateInt} ${ShortMonthMapping[monthInt - 1]} ${yearInt}`
}

export function formatFromISOString(
  date: string,
  template: string = dateFormat,
  shortMonth?: string
): string {
  try {
    const isoDate = parseISO(date)
    if (shortMonth === 'short') {
      // For AM PM time formate and short month
      return `${formatShortMonth(format(isoDate, dateFormat))}, ${format(
        isoDate,
        template
      )}`
    }

    return format(isoDate, template)
  } catch (e) {
    console.log(`can not parse date ISO string: ${date}`)
    return date
  }
}

export function parseTimeString(
  timeString: string,
  referenceDate: Date | number = parseDate.date,
  format: string = timeFormat
): Date {
  if (parseTimeString.cache[timeString]) {
    return parseTimeString.cache[timeString]
  }

  parseTimeString.cache[timeString] = parse(timeString, format, referenceDate)
  return parseTimeString.cache[timeString]
}

parseTimeString.cache = {} as Record<string, Date>

export function getDurationTime(
  startTime: string | null,
  endTime: string | null
): Duration | undefined {
  if (!startTime || !endTime) {
    return
  }

  const start = parseTimeString(startTime)
  const end = parseTimeString(endTime)

  return intervalToDuration({ start, end })
}

export function getNumberOfDay(date: Date): number {
  const dayName = format(date, 'EEE')

  const key = Object.keys(ShortDayMapping).find(
    (key) => ShortDayMapping[key] === dayName
  )

  return Number(key ?? 0) // assign to first day (0) when wrong number
}
