import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  HttpLink,
  type Observable,
  type ServerError,
  makeVar,
} from '@apollo/client'
import { InMemoryCache, type NormalizedCacheObject } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { type ErrorLink, onError } from '@apollo/client/link/error'
import { MAINTENANCE_MODE_KEY } from 'context/MaintenanceContext'
import FastMutex from 'fast-mutex'

import possibleTypes from '../__generated__/possibleTypes.json'
import { SentryLink } from 'apollo-link-sentry'

export interface ErrorWithStatus extends Error {
  status: number
}

const URL = import.meta.env.VITE_REACT_APP_PUBLIC_GRAPHQL_ENDPOINT

export const isTokenEmpty = makeVar<boolean>(false)

type Resolve = () => void

type RefreshTokenResponse = {
  jwt: string
  jwt_expiry: number
  jwt_refresh_token: string
}

let isRefreshingJWT = false
let pendingRequests: Resolve[] = []
let jwtToken: string | undefined

const resolvePendingRequests = () => {
  console.log('resolvePendingRequests')
  // pendingRequests.map((resolve) => resolve())
  pendingRequests = []
}

const jwtRefreshEndpoint =
  URL?.split('/').slice(0, -1).join('/') + '/refresh-token'

export const getJwtFromRefreshToken = async (): Promise<string | undefined> => {
  console.log(' * refresh JWT: start')

  let mutex
  try {
    const refreshToken = localStorage.getItem('refreshToken')
    mutex = new FastMutex({ timeout: 15000 })
    const mutexLockRes = await mutex.lock('sessionId')
    console.dir(mutexLockRes)

    const response: Response = await fetch(jwtRefreshEndpoint, {
      headers: {
        'x-jwt-refresh-token': refreshToken ?? '',
      },
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    })

    if (response.ok) {
      const body = (await response.json()) as RefreshTokenResponse
      localStorage.setItem('refreshToken', body?.jwt_refresh_token)
      jwtToken = body?.jwt
      return body?.jwt
    } else {
      const err: ErrorWithStatus = new Error(
        `Refresh JWT: ${response.status}`
      ) as ErrorWithStatus
      err.status = response.status
      throw err
    }
  } catch (err) {
    console.error('Refresh JWT error:', err)
    throw err
  } finally {
    await mutex?.release('sessionId')
  }
}

let logoutCallback: () => void

export const setLogoutCallback = (callback: () => void) => {
  logoutCallback = callback
}

export const setJWT = (jwt: string | undefined) => {
  jwtToken = jwt
}

export function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  const link = ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink({
      uri: URL,
      credentials: 'include',
    }),
    new HttpLink({
      uri: URL,
      credentials: 'include',
    })
  )

  const authHeaderLink = setContext((_, { headers }) => {
    const authorization = () =>
      jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}
    return {
      headers: {
        ...headers,
        ...authorization(),
      },
    }
  })

  const onErrorCallback: ErrorLink.ErrorHandler = ({
    graphQLErrors,
    networkError,
    operation,
    forward,
  }) => {
    console.log(' * Apollo client onError')

    const statusCode = (networkError as ServerError)?.statusCode
    if (statusCode === 503 || statusCode === 504) {
      localStorage.setItem(MAINTENANCE_MODE_KEY, 'true')
    } else if (statusCode === 204 || statusCode >= 400) {
      console.log(
        `[Network error]: ${(networkError as ServerError).response.status}`
      )
    }

    const serverError = networkError as ServerError
    if (
      statusCode === 500 &&
      serverError.result.error?.message === 'JWT token is empty'
    ) {
      isTokenEmpty(true)
    }

    if (graphQLErrors && Array.isArray(graphQLErrors)) {
      console.log('[GraphQL error]:', graphQLErrors)

      for (const error of graphQLErrors) {
        const { message, locations, path } = error

        if (message === 'JWTExpired') {
          console.log(' * JWT expired, refresh token:')

          let _forward: Observable<string | void | undefined>
          if (isRefreshingJWT) {
            // It will resolve only when we call pendingRequests
            _forward = fromPromise(
              new Promise<void>((resolve) => {
                pendingRequests.push(() => resolve())
              })
            )
          } else {
            isRefreshingJWT = true

            _forward = fromPromise(
              getJwtFromRefreshToken()
                .then((jwt) => {
                  jwtToken = jwt
                  const { headers } = operation.getContext()
                  operation.setContext({
                    headers: {
                      ...headers,
                      Authorization: jwt ? `Bearer ${jwt}` : '',
                    },
                  })

                  isRefreshingJWT = false
                  resolvePendingRequests()
                  return jwt
                })
                .catch((error) => {
                  jwtToken = undefined

                  console.log(' * Error refresh JWT:', error)
                  isRefreshingJWT = false
                  pendingRequests = []

                  if (logoutCallback) {
                    logoutCallback()
                  }
                })
                .finally(() => {
                  console.log(' * Refresh JWT done!')
                })
            ).filter((v) => Boolean(v))
          }

          return _forward.flatMap(() => {
            // retry the request, returning the new observable
            console.log(' * Forwarding operation...')
            return forward(operation)
          })
        }

        if (message === 'Invalid JWT') {
          console.log(' * Invalid JWT, clean session:')

          jwtToken = undefined
          if (logoutCallback) {
            logoutCallback()
          }
        }

        const m = `Message: ${message}`
        const l = `Location: ${JSON.stringify(locations)}`
        const p = `Path: ${JSON.stringify(path)}`
        console.log(`[GraphQL error]: ${m}, ${l}, ${p}`)
      }
    }
  }

  const errorLink = onError(onErrorCallback)

  const sentryLink = new SentryLink()

  // https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
  const cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
      FreeTimeslot: {
        keyFields: ['id', 'startAt', 'endAt'],
      },
    },
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([authHeaderLink, sentryLink, errorLink, link]),
    cache,
  })
}

export const apolloClient = createApolloClient()
